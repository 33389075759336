.dtpicker-bg {
  border: 1px solid #ccc;
  border-radius: 4px;
}
/*.dtpicker-overlay {
  top: 34px!important;
  left: 15px!important;
}*/
.dtpicker-header {
   margin: 0.1em 0.3em;
  .dtpicker-value {
    padding: 0.5em .2em .2em;
    border: 1px solid #aaa;
    background: #ccc url(../images/ui-bg_highlight-soft_75_cccccc_1x100.png) 50% 50% repeat-x;
    border-radius: 4px;
    color: #555;
    font-size: 14px;
  }
  .dtpicker-close {
    display: none;
  }
}
.dtpicker-cont {
  border: 1px solid #ccc;
  border-radius: 4px;
}
.dtpicker-components .dtpicker-comp>* {
  color: #555;
}
.dtpicker-components .dtpicker-compValue {
  background: #e6e6e6 url(../images/ui-bg_glass_75_e6e6e6_1x400.png) 50% 50% repeat-x;
  border: 1px solid #ccc;
}
.dtpicker-buttonCont {
  border-top: 1px solid #ccc;
  height: 47px;
}
.dtpicker-twoButtons .dtpicker-buttonSet {
  margin-top: 10px;
  margin-left: 5px;
  color: #555;
  border: 1px solid #d3d3d3;
  background: #e6e6e6 url(../images/ui-bg_glass_75_e6e6e6_1x400.png) 50% 50% repeat-x;
  -webkit-box-shadow:none;
  box-shadow:none;
}
.dtpicker-twoButtons .dtpicker-buttonClear {
  margin-top: 10px;
  margin-right: 5px;
  color: #555;
  border: 1px solid #d3d3d3;
  background: #e6e6e6 url(../images/ui-bg_glass_75_e6e6e6_1x400.png) 50% 50% repeat-x;
}
.dtpicker-buttonCont .dtpicker-button {
  width: 21%;
  font-size: 14px !important;
}
.dtpicker-content {
  margin: 4px auto;
  padding: 0;
}