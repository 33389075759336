.senceSetting {
    padding-left: 30px;
    padding-right: 30px;
    .senceStting-help {
        background: url('../images/help.png') no-repeat;
        display: inline-block;
        vertical-align: middle;
        width: 14px;
        height: 16px;

        +span {
            display: inline-block;
            vertical-align: middle;
        }
    }
    a {
        cursor: pointer;
    }
    .isOpenSetting {
        margin-left: 21px;
    }
    table {
        .default {
            color: #999;
        }
        tbody {
            font-size: 14px;
        }
    }
    .activeTable{
        background: #f7f9fa;
    }
    .support-cameral {
        margin: 25px 0;
        display: inline-block;
    }
    .clear-setting_header, .detect-setting-header {
        margin-top: 10px;
        position: relative;
        input {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 0;
            margin: 0;
        }
        > i {
            color: rgb(51, 122, 183);
        }
    }
    .setting-height {
            line-height: 34px;
        }
    hr {
        margin-top: 10px;
        border-color: #ddd;
    }
    .detect-modal {
        line-height: 34px;
    }
    .row-common-margin-nova {
        margin-top: 20px;
    }
    .check-box_height {
        line-height: 35px;
    }
    .detect-setting-help {
        color: #999;
        font-size: 12px;
        margin-top: 5px;
        margin-bottom: 0;
        white-space: nowrap !important;
        a:hover {
            text-decoration: underline;
        }
    }
    .detect-reset {
        float: right;
        margin-left: 15px;
    }
    .detect-canvas {
        position: relative;
        margin-bottom: 30px;
        width: 100%;
        img {
            width: 100%;
        }
        canvas {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}
#howDetection {
    .modal-dialog {
        width: 800px;
    }
}
.imgPosition{
    position: relative;
}
.imgPosition img{
    position: absolute;
    top:12px;
    cursor: pointer;
}
.disableClass {
    background: none;
    border: 1px solid transparent;
}
.ableClass{
    border:1px solid #ccc !important;
    background: none;
    border-radius:3px !important;
}
.imgup{
    left: -30px;
}
.imgdown{
    left: -15px;
}.dayContent{
     width: 459px;
     height: 150px;
     border: 1px solid #eee;
     position: absolute;
     border-radius: 4px;
     background-color: #fff;
     z-index: 10000;
 }
 .dayContentHead{
     position: relative;
     width: 100%;
     height: 30px;
     background-color: #eee;
     padding-left: 5px;
     line-height: 30px;
 }
 .contentsUl{
     position: relative;
     top: 0px;
     width: 100%;
     height: 66px;
     display: flex;
     flex-direction: row;
 }
.contentsUl ul {
    padding-left: 16px;
    padding-top: 7px;
}
.contentsUl ul li {
    width: 27px;
    height: 27px;
    margin: 4px;
    border: 1px solid #e8e8e8;
    list-style: none;
    display: inline-block;
    background-color: #f5f5f5;
    border-radius: 1px;
    text-align: center;
    line-height: 27px;
    cursor: pointer;
}
.inputShow{
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
   opacity: 0;
    border: none;
    cursor: pointer;
}
.inputHide{
    display: none;
}
#paddings{
    text-indent:2em !important;
}
.btnTagL{
    border-radius:4px 0 0 4px !important;
    padding: 4px 12px !important;
    float: left;
    width:96px;
}
.btnTagR{
    border-radius:0px 4px 4px 0px !important;
    padding: 4px 12px !important;
    float: left;
    width: 96px;
}
.text-L{
    margin-left: 16.65%;
}
.deletePadding{
    padding: 0 !important;
}
.settingTip{
   color: #9d7437 !important;
    margin-left: .1px;
    min-height:35px !important;
    padding: 0 15px !important;
    line-height: 35px !important;
}
.addpaddings{
    padding-bottom: 15px;
}
.HistoryModals{
    width: 800px;
}
.addmargins{
    margin-bottom: 15px;
}
//option:disabled,option[disabled],option.disabled{ //兼容ie8,9,10以上
//    background: #c8c8c8 !important;
//}
