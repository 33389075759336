@import '../common/common';

.brightnessTemplate {
  .brightnessTemplateList {
    width: 100%;
    box-shadow: 0 2px 2px #dddddd;
    padding-bottom: 100px;
    background-color: white;
    min-height: 77vh;
    .main {
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 35px;
      .operateBth {
        position: relative;
        float: right;
        right: 13px;
        bottom: 15px;
        button {
          margin-right: 5px;
        }
      }
    }

    .nameError {
      position: absolute;
      top: -340px;
    }
  }
  .editDetectTemplate {
    width: 100%;
    box-shadow: 0 2px 2px #dddddd;
    padding-bottom: 100px;
    background-color: white;
    min-height: 77vh;
    .main {
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 35px;
      .table > thead > tr > th {
        font-size: 13px;
      }
      .table-center{
        text-align: center;
      }
      .gamma-slider {
        width:74.5%;
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
        vertical-align: middle;
      }
      .brightness-slider {
        width: 81%;
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
        .slider-val {
          float: left;
          height: 100%;
          &.val1 {
            background: #d9534f;
            width: 20%;
          }
          &.val2 {
            background: #f0ad4e;
            width: 20%;
          }
          &.val3 {
            background: #5cb85c;
            width: 20%;
          }
          &.val4 {
            background: #f0ad4e;
            width: 40%;
          }
        }

      }
      .title_item {
        width: 220px;
      }
      .threshold {
        height: 34px;
        line-height: 31px;
        & > span {
          display: inline-block;
          text-align: left;
          font-size: 13px;
          padding-left: 10px;
          min-width: 45px;
        }
        #custom_brightness, #brightness_gamma, #brightness_failsSet {
          background: #eee;
        }
        .ui-slider-range {
          background: #5cb85c;
        }
        .ui-slider-handle {
          font-size: 16px;
        }
      }
      .brightness-monitor_add {
        cursor: pointer;
        margin-top: 5px;
        display: inline-block;
        span {
          font-size: 15px;
          color: #666;
        }

      }
    }
    .light-icon{
      display: inline-block;
      vertical-align: top;
      margin-right: 5px;
      font-size: 16px;
      line-height: 36px;
      color: #256aaa;
    }
    .nameFormError {
      position: relative;
      top: -373px;
    }
		.nameError {
		  position: relative;
		  top: -337px;
		}
    .repairFormError {
      position: absolute;
      top: -340px;
      left: 0px;
    }
    .clearMP {
      margin: 0;
      padding: 0;
    }
    .introP {
      color: #999;
      font-size: 13px;
    }
  }
}

