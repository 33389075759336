.dtpicker-positions {
    .time-space {
        padding: 9px 15px;
    }
    .error-box {
        position: relative;
    }
    input {
        border-radius: 4px;
        border: 1px solid #ddd;
        cursor: pointer;
        padding-left: 10px;
        width: 280px;
        height: 34px;
    }
    .time-error {
        position: absolute;
        top: -48px;
        left: 50px;
        .formError {
            position: static;
            // top: 300px;
            // left: 370px;
        }
    }
}