.tipBox
{
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 470px;
    height: 270px;
    background-color: #fff;
    border: 1px solid #999;
    border-radius: 3px;
    z-index: 99;

}
.closeLogo
{
    width: 21px;
    position: absolute;
    top: 18px;
    left: 426px;
}
.contentHtml
{
    font-family: Microsoft YaHei;
    position: relative;
    font-size: 24px;
    color: #4A4848;
    top: 0px;
    border-bottom: #ccc solid 1px;
    text-align: center;
    vertical-align: middle;
    height: 148px;
    width: 470px;
    word-wrap: break-word;
    display: table-cell;
    text-indent: 5px;
}
.cancelBtn{
    position: relative;
    left: 52px;
    top: 4px;
    background-color: #607D8B;
    border: none;
    border-radius: 4px;
    width: 41px;
    height: 21px;
    font-size: 11px;
    color: #eee;
}
.conformBtn
{
    position: relative;
    left: 376px;
    top: 10px;
    background-color: #607D8B;
    border: none;
    border-radius: 3px;
    width: 77px;
    text-align: center;
    height: 42px;
    font-size: 22px;
    line-height: 42px;
    color: #eee;
}
.shade{
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 66;
}
.alertTitle{
    font-family: Microsoft YaHei;
    width: 390px;
    font-size: 25px;
    font-weight: 600;
    color: rgba(17, 17, 17, 0.57);
}
.banner{
    width: 100%;
    margin-top: 9px;
    text-indent: 3px;
    border-bottom: #ccc solid 1px;
    height: 52px;
}

/*提交弹出框样式*/
.ajax-container {
   /* display: none;*/
    opacity: 0.7;
    width: 120px;
    height: 120px;
    position: fixed;
    z-index: 1051;
    left: 50%;
    margin-left: -60px;
    top: 20%;
   /* background: #000;*/
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomleft: 5px;
    -moz-border-radius-bottomright: 5px;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    -moz-box-shadow: 0px 0px 9px #000000;
    -webkit-box-shadow: 0px 0px 9px #000000;
    box-shadow: 0px 0px 9px #000000;
}
.ajax-container img {
    width: 60px;
    height: 60px;
    margin-top: 10px;
    margin-left: 30px;
}
.ajax-container p.sys-text {
    color: #fff;
    text-align: center;
    line-height: 40px;
}



/*提交弹出框样式*/
.errAlert{
    opacity: 0.87;
    position: absolute;
    top: 62px;
    left: 150px;
	}
.mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.5);
    z-index: -1;
}