.nv-guide-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}
.nv-guide,
.nv-guide * {
  box-sizing: border-box;
}
.nv-guide {
  position: absolute;
  top: 10vh;
  left: 10vh;

  .nv-guide__body {
    background-color: white;
    border: 1px solid #cfd5e4;

    &::before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      background-color: white;
    }
  }

  &[x-placement="left"] {
    .nv-guide__body::before {
      right: -4px;
      top: 40px;
    }
  }

  &[x-placement="right"] {
    .nv-guide__body::before {
      left: -4px;
      top: 40px;
    }
  }

  &[x-placement="bottom"] {
    .nv-guide__body::before {
      top: -5px;
      left: 20px;
    }
  }

  &[x-placement="top"] {
    .nv-guide__body::before {
      bottom: -5px;
      left: 20px;
    }
  }

  .nv-guide__content {
    padding: 30px 20px 20px 20px;

    .guide-title {
      line-height: 20px;
      margin: 0;
      padding: 0;
      font-size: 14px;
      font-weight: bold
    }

    .guide-content {
      margin: 10px 0 0 0;
      line-height: 28px;
    }
  }

  .nv-guide__link {
    cursor: pointer;
    color: #5196f2;
    text-decoration: none;
  }

  .nv-guide__foot {
    overflow: hidden;
    padding: 14px 20px;
    border-top: 1px solid #cfd5e4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;

    .nv-guide__steps {
      .current {
        color: #5196f2;
      }
    }

    .nv-guide__check {
      margin: 0;
      display: inline-flex;
      align-items: center;
      cursor: pointer;

      >span {
        margin-left: 5px;
      }
    }

    .nv-guide__btn {
      height: 32px;
      line-height: 20px;
      padding: 6px 20px;
      border-radius: 4px;
      background-color: #09C;
      color: white;
      border: none;
      cursor: pointer;
      margin-left: 10px;
      outline: none;
    }
  }
}