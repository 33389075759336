.nav-nova
{
    padding-left: 0;
    margin-bottom: 0;
    color:#666;
    list-style: none;
}
.checkedBackground{
    background-color: #ccc !important;
}
.nav-nova:before,
.nav-nova:after
{
    display: table;
    content: " ";
}
.nav-nova > li
{
    position: relative;
    display: block;
}
.nav-nova > li > a {
    position: relative;
    display: block;
    margin: 0;
    height:43px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 0;
    line-height: 1.2em;
    color: #666;
}
.nav-tabs-nova {
    border-bottom: 1px solid #ddd;
}
.nav-tabs-nova > li {
    float: left;
    /*margin-bottom:4px;*/
}
.nav-tabs-nova > li > a {
    margin-right: -1px;
    background-color: #f1f2f2;
    cursor: pointer;
    border-radius: 4px 4px 0 0;
}
.nav-tabs-nova> li.active> a,
.nav-tabs-nova> li.active> a:hover,
.nav-tabs-nova> li.active> a:focus {
    /*border-bottom: 1px solid #dddddd;*/
    background-color: #FFF;
    cursor: pointer;
}
.nav-tabs-nova .active{
    /*border-bottom: 2px solid #43a3fb;*/
    cursor: default;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent;

}
/*.nav-tabs-nova > li > a:hover {*/
    /*border-color: #eee #eee #ddd;*/
/*}*/
.nav-nova > li > a:hover,
.nav-nova > li > a:focus {
    text-decoration: none;
    /*background-color: #eee;*/
}
.nav-tabs-nova> li> button {
    margin-left: 5px;
    margin-right: 5px;
}

/*input输入框*/
.form-input-nova {
    position: relative;
    display: block;
    width: 100%;
    height: 36px;
    padding: 6px 12px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #666;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ddd;
    border-radius: 4px;
}
.form-input-nova:focus {
    outline: 0;
    border-color: #ccc;
    box-shadow: none;
}
.form-input-nova::-moz-placeholder {
    color: #666;
    opacity: 1;
}
.form-input-nova:-ms-input-placeholder {
    color: #666;
}
.form-input-nova::-webkit-input-placeholder {
    color: #666;
}
.form-input-nova[disabled],
.form-input-nova[readonly],
fieldset[disabled] .form-input-nova {
    background-color: #eee;
    opacity: 1;
}
.form-input-nova[disabled],
fieldset[disabled] .form-input-nova {
    cursor: not-allowed;
}
.form-input-nova.err
{
    border: 1px solid #e3a49f;
}
/*搜索框start*/
.nova-input-search
{
    float: left;
}
.nova-input-search input {
    display: inline;
    width: 547px;
    height: 34px;
    float: left;
    border-radius: 3px 0 0 3px;
    border: solid #ccc 1px;
    line-height: 32px;
    vertical-align: middle;
}
.nova-input-search .search-btn {
    float: left;
    width: 34px;
    height: 34px;
    margin-left: -1px;
    border: solid #ccc 1px;
    border-radius: 0 3px 3px 0;
    background-image: url('@{root}/images/search.png');
    background-repeat: no-repeat;
    background-position: 50%;
    cursor: pointer;
}
.btn-default:focus, .btn-default:hover
{
    //background-color: #e0e0e0;
}
/*搜索框end*/
.div-background-nova{
    background-color: #fff;
    padding-top: 45px;
    padding-bottom: 45px;
    min-height: 77vh;
}
.pageNum
{
    color: #666;
    float: right;
    margin-right: 10px;
}
.dropDown
{
    height: 34px;
    width: 58px;
    border-radius: 3px;
    border-style: solid;
    border-color: #ddd;
    border-width: 1px;
    margin-right: 0;
    font-size: 13px;
}

.attr-title
{
    color: #999;
}
#content table th[ng-click]{
    cursor: pointer;
}
#content table th[ng-click],#content table tr[ng-click]{

    /*padding-left: 12px;*/
}
.alignCenter{
    height: 34px;
    line-height: 34px;
}
.alert-comm {
    position: absolute;
    left: 50%;
    width: 408px;
    margin-left: -223px;
    margin-top: -66px;
    font-size: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    text-shadow: 0 1px 0 rgba(255,255,255,.2);
    box-shadow: inset 0 1px 0 rgba(255,255,255,.6), 0 1px 8px rgba(0,0,0,.5);
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.6), 0 1px 8px rgba(0,0,0,.5);
    z-index: 2;
}
.alert-close {
    position: relative;
    right: -21px;
    height: 17px;
    line-height: 17px;
    float: right;
    color: #000;
    padding: 0;
    border: 0;
    cursor: pointer;
    background: transparent;
    font-size: 12px;
    font-weight: bold;
    -webkit-appearance: none;
    filter: alpha(opacity=20);
    opacity: .3;
    outline: none;
}
button.disabled{
    opacity: 0.5;
}
tr.selecteds {
    background-color: #d9edf7 !important;
}
.commodityManage .popover-err input,.commodityManage .popover-err textarea, .commodityManage .popover-err .popover {
    border-color: red;
}
.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    vertical-align: middle !important;
    word-break: break-word;

}
.nav-tabs > li > a{
    color: #333;
}
.nav-tabs > li > a.active{
    color: #555;
    cursor: default;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
}
.nav-tabs li.last{
    float: right;
    padding-right: 27px;
}
.noData{
    text-align: center;
    background-color: #f9f9f9;
    line-height: 40px;
    height: 40px;
    /* margin-top: -21px; */
    border-bottom: 1px solid #ddd;
    width: 98%;
    margin: 0 auto;
    margin-top: -21px
}
.pagefun span.disabled{
    display: none !important;
}
.pagefun span.current{
    color: #fff !important;
    background-color: #337ab7 !important;
}
#container #content ul.top-nav {
    background: #f2f2f2;
}
.form-Input {
    margin-top: 20px;
    margin-bottom: 15px;
}

.btn-primary {
    background-image: -webkit-linear-gradient(top, #337ab7 0, #265a88 100%);
    
}
.stop_countdown {
    position: fixed;
    padding: 10px 23px 10px 12px;
    min-height: 80px;
    right: 21px;
    bottom: 300px;
    z-index: 1002;
    border-radius: 4px;
    background: #3370FF;
    box-shadow: 0px 0px 16px 0px rgba(0, 30, 74, 0.40);
    &_close {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 8px;
        height: 9px;
        cursor: pointer;
    }
    &_tip {
        color: #FFF;
        font-size: 12px;
        line-height: 14px;
    }
    &_str {
        color: #FFF;
        font-size: 19px;
        font-weight: bold;
        line-height: 30px;
        min-height: 30px;
        letter-spacing: 1.5px;
    }
    &_btn {
        width: 48px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        font-size: 12px;
        border-radius: 2px;
        color: #3370FF;
        background: #FFF;
        cursor: pointer;
    }
}
.suggest_smart {
    display: block;
    position: fixed;
    width: 64px;
    height: 72px;
    right: 21px;
    bottom: 75px;
    z-index: 1002;
}
.suggest_smart > a {
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    box-sizing: border-box;
    line-height: 1.3;
    text-decoration: none;
    font-size: 13px;
    background-color: #fff;
    background-image: url('../images/feedbackEdit.png');
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center 22%;
    color: #444 !important;
    padding: 10px 12px;
    border: 1px solid #eee;
    border-radius: 4px;
    box-shadow: 3px 2px 22px #ccc;
}
.suggest_smart > a:hover {
    background-color: #ddd;
}
.langen {
    width: 95px;
    height: 42px;
    transform: rotate(-90deg);
    transform-origin: 110px;
    text-align: center;
    bottom: 162px;
    right: 58px;
    box-shadow: -2px 3px 22px #ccc;
}
.langen a{
    padding: 0;
    line-height: 42px;
}
.suggest_smart a:hover {
    text-decoration: none;
}
.btn-primary{
    background-image: -webkit-linear-gradient(top,#337ab7 0,#265a88 100%);
}
.tangram-suggestion-main {
    z-index: 10000;
    &.hide {
        display: none !important;
    }
}
#MicrosoftNav {
    display: none;
}
.MicrosoftMap div.as_container_search {
    margin-top: -13px;
    width: 420px;
}
.MicrosoftMap .Infobox div.infobox-info {
    color: #222;
}
.MicrosoftMap .as_container a.suggestLink {
    line-height: 20px;
    padding-top: 0;
    padding-bottom: 0;
}
div.MicrosoftMap .as_container ul {
    padding-top: 0;
}
.MicrosoftMap .as_container p.line1 {
    line-height: 30px;
    font-size: 12px;
}
.MicrosoftMap .as_container p.line2 {
    display: none;
}
.pac-container {
    z-index: 10000;
    &.hide {
        display: none !important;
    }
}
.ui-datepicker{
    z-index: 10 !important;
}
.tableSorts {
    .asc, .desc, .both {
        padding-left: 20px;
        background-repeat: no-repeat;
        background-position: center left;
        cursor: pointer;
    }
    .asc {
        background-image: url('../images/sort_asc.png');
    }
    .desc {
        background-image: url('../images/sort_desc.png');
    }
    .both {
        background-image: url('../images/sort_both.png');
    }
}
.no-scroll {
    overflow: hidden;
}
.asc, .desc, .both {
    padding-left: 20px;
    background-repeat: no-repeat;
    background-position: center left;
    cursor: pointer;
}
.asc {
    background-image: url('../images/sort_asc.png');
}
.desc {
    background-image: url('../images/sort_desc.png');
}
.both {
    background-image: url('../images/sort_both.png');
}
.modal-header [aria-hidden] {
    font-size: 24px;
}
.ng-cloak, [ng-cloak]{
    display: none;
}
.icon-search {
    background: url(/smart/build/images/search.png) no-repeat;
    display: inline-block;
    width: 13px;
    height: 16px;
    vertical-align: middle;
}
.parameter {
    height: 36px;
    line-height: 36px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    vertical-align: middle;
}
input[type=checkbox], input[type=radio]{
    margin: 0;
}
.ellipsis_display {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow:hidden;
    max-width: 120px;
}