.camera-items {
    padding: 20px 30px;
    .camera-items_date {
        font-size: 18px;
        font-weight: 700;
        margin-right: 10px;
    }
    .camera-items_name {
        font-size: 14px;
        font-weight: 700;
        color: #7a7a7a;
    }
}