.photolist-Date{
  font-size: 18px;
  font-weight: bold;
  margin: 0px 10px 0px 0
}
.lookbigModal{
  width:800px;
  img{
    width: 100%;
  }
}
.photolist-canmeraName{
  font-size: 14px;
  font-weight: 700;
  color: #7a7a7a;
}
.photolistHead{
  margin: 20px 0;
  padding: 0;
}
.addpadding{
  padding:0 45px;
  .addpadding-search {
    padding-left: 0px;
    margin-bottom: 16px;
  }
  .addpadding-datebtn {
    right: 15px; 
    color: #999999;
  }
}
.modal-title{
  float:left;
}
.imgheads{
  background: url(/smart/build/images/photolist_time.png) no-repeat left;
  padding:2px 0 0 25px;
  margin: 9px;
  font-size: 18px;
  font-weight: 550;
}
.photolist-imgArr{
  display: flex;
  //justify-content: space-around;
  flex-wrap:wrap;
  li {
     width: 23%;
     height: 233px;
     margin:13px;
     overflow: hidden !important;
    img{
      width: 100%;
      cursor: pointer;
      transition:transform .3s linear;
      &:hover{
        transform: scale(1.1,1.1);
      }
    }
  }
}
.photolist-imgDatas{
  position: relative;
}
.photolist-img-title{
  background: rgba(0,0,0,.5);
  position: absolute;
  bottom:0;
  width: 100%;
  height: 15%;
  line-height: 15%;
  display: block;
}
.photolist-icon{
  width: 37%;
  height:100%;
  display: flex;
  align-items: center;
  background: url('../images/photolist-time.png') no-repeat 10px center;
  background-size: contain;
  padding:0 50px 0 60px;
  text-align: center;
  line-height: 100%;
  color: white;
}
.returnTop{
  width:50px;
  height: 50px;
  position: fixed;
  bottom:20px;
  right: 40px;
  cursor: pointer;
  display: none;
  //background:#e5e5e5;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.returnTop a{
  display: block;
  margin:0 auto;
  width: 90%;
  height: 90%;
  background: url("/smart/build/images/renturnTop.png") no-repeat center center;
}