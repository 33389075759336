.reportListShow{
  padding: 10px 30px;
  th{
    color: #333;
  }
  .table-bordered>tbody>tr>td, .table-bordered>thead>tr>th{
    padding: 10px 18px;
  }
  .table{
    margin-bottom: 0px;
  }
  .Abnormal_Red{
    color:red;
  }
  .nomal_fontColor{
    color: #666
  }
  .noRecord{
    background: #EEEEEE;
  }
}

