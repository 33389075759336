.account{
    width: 100%;
    box-shadow: 0 2px 2px #dddddd;
    padding-bottom: 100px;
    background-color: white;
    min-height: 77vh;
}
.account .title{
    height: 52px;
    width: 100%;
    background-color: #fbfbfb;
    border-bottom: 1px solid #e8e8e8;
    text-align: left;
    line-height: 52px;
    text-indent: 15px;
    font-size: 17px;
}
.account .main{
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
}
.account.table{
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    margin-top: 16px;
}
.account .pagefun{
    text-align: left;
    color: #797979;
    float: right;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 15px;
}
.account .pageNum{
    color: #666;
    float: right;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.account .dropDown{
    height: 31px;
    width: 58px;
    border-radius: 3px;
    border-style: solid;
    border-color: #ddd;
    border-width: 1px;
    margin-right: 0;
    font-size: 13px;
}
.table-striped a{
    color: #5cb95c;
}
/*错误信息的两种显示  baseSetting （表单） selfSetting（元素正上方）*/
.baseSetting {
    position: absolute;
    top: -340px;
    z-index: 100000;
    &.phoneinline {
        left: -145px;
    }
    &.phoneCode,&.emailCode {
        left: -130px;
    }
}
.selfSetting {
    position: absolute;
    top: -346px;
    left: -300px;
}
