.nova-ui-tooltip {
  position: absolute;
  box-sizing: border-box;
  font-size: 12px;
  padding: 8px 10px;
  line-height: 18px;
  display: inline-block;
  max-width: 640px;
  min-width: 60px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border: 1px solid #dce3f3;
  color: #464c5b;
  transition: opacity 0.2s;
}

.nova-ui-tooltip * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.nova-ui-tooltip .ui-tooltip-title {
  font-weight: normal;
  margin-bottom: 5px;
}

.nova-ui-tooltip .ui-tooltip-content {
  overflow: hidden;
}

.nova-ui-tooltip::before,
.nova-ui-tooltip::after {
  content: '';
  position: absolute;
  border: 8px solid transparent;
}

.nova-ui-tooltip::before {
  border-width: 6px;
  z-index: 1;
}

.nova-ui-tooltip.ui-tooltip_top::before,
.nova-ui-tooltip.ui-tooltip_top::after,
.nova-ui-tooltip.ui-tooltip_bottom::before,
.nova-ui-tooltip.ui-tooltip_bottom::after {
  top: 100%;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.nova-ui-tooltip.ui-tooltip_top::before {
  border-top-color: #fff;
}

.nova-ui-tooltip.ui-tooltip_top::after {
  border-top-color: #dce3f3;
}

.nova-ui-tooltip.ui-tooltip_bottom::before {
  top: -12px;
  border-bottom-color: #fff;
}

.nova-ui-tooltip.ui-tooltip_bottom::after {
  top: -16px;
  border-bottom-color: #dce3f3;
}

.nova-ui-tooltip.ui-tooltip_left::before,
.nova-ui-tooltip.ui-tooltip_left::after,
.nova-ui-tooltip.ui-tooltip_right::before,
.nova-ui-tooltip.ui-tooltip_right::after {
  top: 50%;
  left: 100%;
  transform: translate3d(0, -50%, 0);
}

.nova-ui-tooltip.ui-tooltip_left::before {
  border-left-color: #fff;
}

.nova-ui-tooltip.ui-tooltip_left::after {
  border-left-color: #dce3f3;
}

.nova-ui-tooltip.ui-tooltip_right::before {
  left: -12px;
  border-right-color: #fff;
}

.nova-ui-tooltip.ui-tooltip_right::after {
  left: -16px;
  border-right-color: #dce3f3;
}

.nova-ui-hide {
  display: none !important;
}
