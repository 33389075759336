.screenlist {
    .listSet {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .search-screenList {
        cursor: text;
    }
    .verifyError {
        font-size: 12px;
        color: #ec3324;
        margin-top: 10px;
        
    }
    .space {
        height: 27px;
    }
    #isChecked {
        vertical-align: middle;
        margin: 0;
    }
    .icon-search {
        background: url('/smart/build/images/search.png') no-repeat;
        display: inline-block;
        width: 13px;
        height: 16px;
        vertical-align: middle;
    }
    &-header {
        display: none;
        height: 55px;
        min-width: 850px;
        line-height: 55px;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
        border-bottom: 1px solid #ddd;
        background: #fff;
        padding: 0 52px 0 52px;
        .set-btn  {
            margin-right: 5px;
        }
        span.screen-header_title {
            width: 263px;
            padding-left: 0;
        }
        .right {
            width: calc(~"100% - 285px");
            height: 100%;
            float: right;
            .btn_groups {
                width: 43px;
                height: 37px;
                display: inline-block;
            }
            .tileImg {
                background-image: url(/smart/build/images/tiles.png);
                background-size: 210% 160%;
                background-repeat: no-repeat;
                background-position: -9px -7px;
                width: 100%;
                height: 100%;
                display: inline-block;
            }
            &.fullContent {
                width: calc(~"100% - 10px");
            }
        }
    }
    td.checkcolor {
        background-color: #d9edf7;
    }
    .screen-header {
        padding: 10px 0;
        height: 55px;
        background-color: #fff;
        line-height: 30px;
        &_title {
            width: 295px;
            height: 35px;
            line-height: 35px;
            display: inline-block;
            vertical-align: middle;
            padding-left: 30px;
        }
        &_icon {
            cursor: pointer;
            margin-left: -14px;
        }
        .closeFilter {
            margin-left: 14px;
        } 
    }
    .screen-content {
        min-height: 950px;
        padding: 10px 30px;
        background-color: #fff;
        &_nav {
            width: 15%;
            display: inline-block;
            vertical-align: top;
            min-height: 950px;
            margin-right: 15px;
            border-right: 1px solid #ddd;
        }
        &_list {
            width: calc(~ '100% - 285px');
            display: inline-block;
            vertical-align: top;
            min-height: 950px;
            position: relative;
        }
        .fullContent {
            width: 100%;
        }
        .status-select {
            display: inline-block;
            position: absolute;
            top: -64px;
            left: 0px;
            width: 100%;
            border-bottom: 1px solid #e4e4e4;;
            li {
                float: left;
                cursor: pointer;
                height: 50px;
                line-height: 50px;
                padding-left: 0px;
                padding-right: 0px;
                margin-right: 25px;
                text-align: center;
                &:hover {
                    border-bottom: 2px solid #6b849a;
                }
            }
            .active, .active:hover{
                border-bottom: 5px solid #6b849a;
            }
        }
        .btn_groups {
            width: 43px;
            height: 37px;
            display: inline-block;
        }
        .tileImg {
            background-image: url('/smart/build/images/tiles.png');
            background-size: 210% 160%;
            background-repeat: no-repeat;
            background-position: -9px -7px;
            /* background: saddlebrown; */
            width: 100%;
            height: 100%;
            display: inline-block;
        }
        .set-btn {
            margin-right: 5px;
        }
        .screen-list_header {
            margin: 10px 0 0;
        }
        .screen-pages {
            line-height: 34px;
        }
        .asc, .desc, .both {
            padding-left: 20px;
            background-repeat: no-repeat;
            background-position: center left;
            cursor: pointer;
        }
        .asc {
            background-image: url('../images/sort_asc.png');
        }
        .desc {
            background-image: url('../images/sort_desc.png');
        }
        .both {
            background-image: url('../images/sort_both.png');
        }
    }
    .all {
        color: #337ab7;
    }
    .error {
        color: red;
    }
    .alarm {
        color: #e67e22;
    }
    .off {
         color: #95a5a6;
    }
    .normal {
        color: #1abc9c;
    }
    .gray {
        color: #999;
    }
    .hour, .minute {
        width: 50px;
        display: inline-block;
        vertical-align: middle;
    }
    .color-select {
        position: relative;
        width: 54px;
        height: 34px;
        display: inline-block;
        cursor: pointer;
    }
    .color-default {
        position: absolute;
        width: 24px;
        height: 19px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    .bg1, .fc1 {
        background-color:#fff;
    }

    .bg2, .fc2 {
        background-color:#fbada1;
    }

    .bg3, .fc3 {
        background-color:#f7bac2;
    }

    .bg4, .fc4 {
        background-color:#f8d6b0;
    }

    .bg5, .fc5 {
        background-color:#b7eecf;
    }

    .bg6, .fc6{
        background-color:#7fd9fe;
    }

    .bg7, .fc7 {
        background-color:#b2adff;
    }
    .bg2, .bg3, .bg4m, .bg5, .bg6, .bg7, {
        width: 25px;
        height: 25px;
    }
    .bg2:hover,.bg3:hover,.bg4:hover,.bg5:hover,.bg6:hover,.bg7:hover{
       border:3px solid #999;
    }
    .colorSelectBox{
        width: 285px;
        height: 100px;
        border: 1px solid #eee;
        position: relative;
        left: 390px;
        background-color: #fff;
        top: -24px;
    }
    .colorHear{
        position: relative;
        width: 100%;
        height: 30px;
        background-color: #eee;
        padding-left: 10px;
    }
    .colorContent{
        position: relative;
        top: 0px;
        width: 100%;
        height: 66px;
        display: flex;
        flex-direction: row;
    }
    .colorCon{
        width: 27px;
        height: 27px;
        margin: 10px;
        border: 1px solid #eee;
        cursor: pointer;
    }
    #selectSet {
        span {
            vertical-align: middle;
        }
        input[type=checkbox] {
            position: relative;
            //top: 3px;
        }
    }
    .table-striped {
        th, td {
            padding-left: 20px;
        }
        td {
            vertical-align: top !important;
        }
        a {
            color: #337ab7;
        }
        .pAlert {
            color: #999;
        }
    }
    tbody {
        font-size: 14px;
    }
    .self-group {
        position: relative;
    }
    .selectModel-body {
        padding: 17px 17px 50px;
    }
    #selectModel {
        line-height: 32px;
        ul.selectModel-box {
            padding: 7px 55px;
        }
        li{
           list-style: none;
           &:not(:first-child) {
                padding-left:10px;
           }
        }
        ul{
            padding: 7px 60px;
        }
        .ulWidth{
            width: 25%;
            padding: 0;
            padding-left: 30px;
        }
        .interLabel {
            display: inline-block;
            vertical-align: top;
        }
        .selectModel-item {
            width: calc(~ "25% - 10px");
            float: left;
            ul {
                width: 100%;
                display: block;
                padding-left: 0px;
            }
        }
        .selectModel-infotext {
            display: inline-block;
            max-width: 182px;
            vertical-align: top;
            margin-left: 6px;
        }
    }

    .content-left{
        width: 270px;
        display: inline-block;
        vertical-align: top;
        min-height: 950px;
        margin-right: 15px;
        border-right: 1px solid #ddd;
    }
    .content-left-title{
        /*flex-grow: 0.05;*/
        width: 295px;;
        /*border-right: 1px solid #ddd;*/
        /*margin-right: 20px;*/
        /* padding-bottom: 0px !important; */
        margin-bottom: -11px;
        line-height: 40px;
        height: 46px;
    }
    .content-left .content{
         /*margin-top: 28px;*/
        padding-left: 5px;
     }
    .content-left ul{
       margin: 0;
        padding: 0;

    }
    .content-left ul li{
        text-decoration: none;
        list-style: none;
        line-height: 29px;
        /* height: 29px; */
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        word-break: break-all;


    }
    .content-left ul .subLi{

        padding-left: 19px;
        text-indent: -19px;
        display: table;
        margin-left: 15px;
    }
    .content-left ul .subLi .subSpan{
        margin-left: -19px;
    }
    .content-left ul li input{

        cursor: pointer;
    }
    .fileter-list {
        margin-bottom: 15px;
    }
    .filterResult{
        ul{
            display: inline-block;
            vertical-align: top;
            padding: 0px;
            margin-bottom: 10px;
        }
        li{
            list-style: none;
            background-color: #337ab7;
            color: #fff;
            padding: 4px 9px 4px 9px;
            border-radius: 2px;
            display: inline-block;
            margin-right: 8px;
            margin-top: 6px;
        }
        li img{
            padding-left: 10px;
            cursor: pointer;
        }
        ul .clear{
            background: none;
            color: #999;
            cursor: pointer;
        }
    }  
}
