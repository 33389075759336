
@import '../common/common';
#sys-tip{
    word-wrap:break-word !important;
}
.addmargin{
    margin-top: 25px;
}
.batch-sencePicture {
    padding-top: 30px;
    .open-text {
        line-height: 30px;
    }
}
.sencePicture {
    .historyPic, .detectHistory, .newSence {
        padding-left: 30px;
        padding-right: 30px;
    }
    
    .newSence-picture_isShowLine {
        padding: 0 .2%;
        text-align: center;
        line-height: 25px;
        cursor: pointer;
        margin-top: 5px;

        float: right;
        #isShowLine {
            margin-right: 6px;
            margin-top: -2px !important;
            vertical-align: middle;
        }
        .show-picture {
            width: 1130px;
            margin: 0 auto;
        }
    }
        .sencePicture-Input {
            margin-top: 15px;
            select {
                display: inline-block;
                width: 210px;
                //margin: 15px 0 15px 0;
            }
        }
        .detect-list {
            tbody, thead {
                font-size: 14px;
            }
            th:first-child {
                width: 300px;
            }
            th:not(:first-child) {
                min-width: 300px;
            }
            td {
                a {
                    color: #337ab7;
                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
        }
        .default {
            display: inline-block;
            vertical-align: bottom;
        }
        .setting {
            float: right;
            color: #333 !important;
            margin-right: 30px;
            margin-top: -40px;
        }
        .Suggesteds {
            width: 100%;
            text-align: right;
            height: 25px;
            span {
                color: #337ab7;
                cursor: pointer;
            }
        }
    }
.newSence {
    padding-top: 20px;
    .newSence-select {
        width: 210px;
        height: 34px;
        outline: none;
        border-radius: 4px;
        border: 1px solid #ddd;
        padding-left: 10px;
    }
    .default-picture {
        width: 1130px;
        height: 630px;
        margin: 15px auto 0;
        >img {
            width: 100%;
            height: 100%;
        }
    }
    .ad-gallery {
        width: 75%;
        max-width: 1345px;
        margin-top: 30px;
        &.js-origin {
            display: none;
        }   
    }
    .ad-image {
        width: 100% !important;
        height: 100% !important;
        top: 0 !important;
        left: 0 !important;
        img { 
            width: 100%;
            max-width: 1345px;
            //height: 100%;
        }
    }
    .ad-image-wrapper {
        width: 100%;
        img {
            height: auto !important;
        }
        position: relative;
    }
    .newSence-pictureNewTitle{
        position: relative;
        width: 87.4%;
    }
    .newSence-picture_more {
        width: 80px;
        margin: 0 auto;
        //position: absolute;
        //right: 0%;
        float: right;
        display: inline-block;
        &::after{
            content:'';
            display: block;
            clear: both;
        }
        img {
            float: right;
            margin-bottom: 10px;
        }
    }
    canvas {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
    }
}
#showSuggesteds{
    .Suggesteds{
        width: 800px;
        tbody{
            font-size: 14px !important;
           td li{
                padding: 5px 0 !important;
            }
        }
    }
}
#checkoutHistoryModal {
    .HistoryModal {
        width: 800px;
    }
    .checkout-modal-title span{
        font-size: 24px;
    }
    .checkout-modal-detial {
        margin-bottom: 10px;
    }
    .checkout-modal-table {
        border: 1px solid #ddd;
        width: 100%;
        margin-bottom: 20px;
    }
    .checkout-modal-table tr {
        border-bottom: 1px solid #ddd;
        height: 35px;
    }
    .checkout-modal-table tr td, .checkout-modal-table tr th{
        padding-left: 10px;
        border-right: 1px solid #ddd;
    }
    .checkout-modal-table tr th:nth-child(1), .checkout-modal-table tr th:nth-child(2) {
        width: 200px;
    }
    .checkout-image-title {
        margin-bottom: 10px;
    }
    .checkout-image-cont {
        width: 100%;
        min-height: 400px;
        border: 1px solid #ddd;
        padding: 5px;
        margin-bottom: 10px;
    }
    .checkout-image-cont img {
        width: 100%;
    }
    .repair-status {
        background-size: cover;
        width: 63px;
        height: 61px;
        float: right;
    }
}
.dataTables_empty {
    text-align: center;
}
.btn-default{
    text-shadow: 0 1px 0 #fff;
    background-image: -webkit-linear-gradient(top,#fff 0,#e0e0e0 100%);
    background-image: -o-linear-gradient(top,#fff 0,#e0e0e0 100%);
    background-image: -webkit-gradient(linear,left top,left bottom,from(#fff),to(#e0e0e0));
    background-image: linear-gradient(to bottom,#fff 0,#e0e0e0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe0e0e0', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    background-repeat: repeat-x;
    border-color: #dbdbdb;
    border-color: #ccc;
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
}