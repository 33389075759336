.pagefun{
    text-align: left;
    color: #797979;
}
.pagefun a{
    display: inline-block;
    color: #797979;
    display: inline-block;
    height: 32px;
    line-height: 32px;
    width: 30px;
    text-align: center;
    border: 1px solid #dddddd;
    margin: 0 1px;
    border-radius: 4px;
    vertical-align: middle;
}
.pagefun a:hover{
    text-decoration: none;
    border: 1px solid #dddddd;
}
.pagefun span.current{
    display: inline-block;
    height: 32px;
    line-height: 32px;
    /*width: 30px;*/
    padding: 0px 10px;
    text-align: center;
    margin: 0 1px;
    color: #797979;
    background-color: #f6f6f6;
    border: 1px solid #dddddd;
    border-radius: 4px;
    vertical-align: middle;
}
.pagefun span.disabled{
    display: inline-block;
    height: 32px;
    line-height: 32px;
    padding: 0 4px;
    margin: 0 1px;
    color: #bfbfbf;
    background: #f2f2f2;
    border: 1px solid #bfbfbf;
    border-radius: 4px;
    vertical-align: middle;
}
.pagefun .prevPage{
    padding: 0px 4px;
}
.pagefun .nextPage{
    padding: 0px 4px;
}

.pagefunMedia{
    text-align: left;
    color: #797979;
}
.pagefunMedia a{
    display: inline-block;
    color: #797979;
    display: inline-block;
    height: 32px;
    line-height: 32px;
    width: 30px;
    text-align: center;
    border: 1px solid #dddddd;
    margin: 0 1px;
    border-radius: 4px;
    vertical-align: middle;
}
.pagefunMedia a:hover{
    text-decoration: none;
    border: 1px solid #dddddd;
}
.pagefunMedia span.current{
    display: inline-block;
    height: 32px;
    line-height: 32px;
    width: 30px;
    text-align: center;
    margin: 0 1px;
    color: #797979;
    background-color: #f6f6f6;
    border: 1px solid #dddddd;
    border-radius: 4px;
    vertical-align: middle;
}
.pagefunMedia span.disabled{
    display: inline-block;
    height: 32px;
    line-height: 32px;
    padding: 0 4px;
    margin: 0 1px;
    color: #bfbfbf;
    background: #f2f2f2;
    border: 1px solid #bfbfbf;
    border-radius: 4px;
    vertical-align: middle;
}
.pagefunMedia .prevPage{
    padding: 0px 4px;
}
.pagefunMedia .nextPage{
    padding: 0px 4px;
}