.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  loat: left;
  color: #555;
  font-weight: bold;
  opacity: 1;
}
.ui-widget-content {
  border: 1px solid #ccc;
}
.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
  border: 1px solid #adc2d4 !important;
  background: #d3e3f1 50% 50% repeat-x !important;;
  color: #363636 !important;;
}
.dtpicker-components .dtpicker-comp>:hover {
  color: #555;
}

.dtpicker-buttonCont .dtpicker-button {
  font-size: 1.4em;
}
.ui-datepicker th {
   padding: 0.7em 0em 0.7em 0.3em !important;
 }