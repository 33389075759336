* {
  font-size: 14px;
}
.report-nav{
    background:#f2f2f2;
}
.report{
  .fontRed{
    color: red !important;
  }
  .nomal{
    color: #666 !important;
  }
  .report-nav{
     background: #f2f2f2 !important;
     &>li{
       margin-bottom: -2px;
     }
  }
  .setting{
    float: right;
    color: #333!important;
    margin-right: 30px;
    margin-top: -40px;
  }
  .report-new, .report-history{
     padding:10px 30px;
  }
  table{
    margin-bottom: 20px;
    th.sorting_asc{
      padding-left: 18px;
      background-image:url("../../images/sort_asc.png");
      background-repeat:no-repeat;
      background-position: center left;
      cursor: pointer;

    }
    th.sorting_desc{
      padding-left: 18px;
      background-image:url("../../images/sort_desc.png");
      background-repeat:no-repeat;
      background-position: center left;
      cursor: pointer;
    }
    th.sorting{
      padding-left: 18px;
      background-image: url("../../images/sort_both.png");
      background-repeat:no-repeat;
      background-position: center left;
      cursor: pointer;
    }
    td{
      padding: 10px 18px;
      word-break: break-all;
      &>a{
        color: #337ab7 !important;
      }
      &>a:hover {text-decoration:underline;}
    }
    th{
      padding: 10px 18px;
      border-bottom: 1px solid #ccc;
    }
    .showReports{
      cursor: pointer;
    }
  }
}
.bar{
  color:#337ab7;
}
.bar span{
  padding-left: 10px;
}
.lableTab{
  padding-bottom: 5px;
}
.lableTab label{
  font-weight: normal;
  margin: 0;
}
.lableTab span{
}

.checSub{
  text-align:center;
}

.set{
  position: relative;
  top: -38px;
  right: -9px;
}
.noRecord{
  text-align: center;
  line-height: 40px;
  height: 40px;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.pageJump input {
  width: 51px !important;
}
.pageJump {
  float: left;
  cursor: pointer;
  padding-left: 12px;
  /* display: none;*/
  line-height: 36px;
}
.page-info{
  float: left;
}
.innerTable{
  padding:0!important;
}