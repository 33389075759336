.userSuggest {
    .form-label {
        text-align: right;
        height: 34px;
        line-height: 34px;
    }
    .require {
        color: red;
    }
    .form-line {
        margin-bottom: 20px;
    }
    .userBtn {
        margin-right: 10px;
    }
    .fixSpace {
        padding-left:0;
    }
    .user-content {
       /* padding-right: 30px;*/
        position:relative;
    }
    .cont-place {
        position: absolute;
        left: 10px;
        top: 39px;
       /* z-index: 10000;*/
        color: #999;
        font-size:14px;
        @media screen and (max-width: 1199px) {
            top: 59px;
        }
    }
    #editor {
        width: 100% !important;
        min-height: 250px;
        z-index: 999;
        /*ul {
            padding-left: 40px;
            list-style-type: disc;
            li {
                list-style-type:disc;
            }
        }
        ol {
            margin:0;
            li {
                list-style-type: inherit;
            }
        }*/
        ul, ol {
            padding-left: 20px;
        }
        ol {
            li {
                list-style-type: decimal;
            }
        }
        ul {
            li {
                list-style-type: disc;
            }
        }
        a, a:active, a:hover, a:link, a:visited {
            color: blue;
            text-decoration: underline;
        }
    }
    .edui-container {
        box-shadow: none;
        background: transparent;
        .edui-editor-body {
            background: transparent;
        }
    }
    .computed {
        height: 23px;
        width: calc(~"100% + 1px");
        border: 1px solid #d4d4d4;
        border-top: none;
        text-align: right;
        color: #aaa;
        line-height: 23px;
        font-size: 12px;
        padding-right: 10px;
    }
    .upload {
        display:inline-block;
        position: relative;
        overflow: hidden;
        height: 34px;
        width: 90px;
        padding: 0;
        .upload-text {
            position: absolute;
            top: 0;
            left: 0px;
            right: 0;
            bottom: 0;
            cursor: pointer;
            margin: 0;
            line-height: 34px;
            border-radius: 4px;
            padding: 0;
        }
        input {
            display: none;
        }
    }
    .form-discription {
        margin-left: 20px;
        height: 34px;
        line-height: 34px;
        vertical-align: top;
        color: #999;
    }
    .submit {

    }
    .uploader {
        height: 30px;
        line-height: 30px;
        background: #ebf1f7;
        padding: 0 10px;
        margin-bottom: 5px;
        color: #337ab7;
    }
    .uploader-delete {
        font-size: 21px;
        color: #000;
        opacity: .2;
        line-height: 1;
        float: right;
        margin-top: 2px;
        cursor: pointer;
        width: 15.58px;
        height: 21px;
    }
    .dropdown-menu li {
        padding-left: 0!important;
        width: auto;
        height: auto;
        padding-top: 0;
        line-height: 32px;
    }
    .edui-combobox-menu .edui-combobox-item .edui-combobox-icon {
        vertical-align: middle;
    }
    .edui-combobox .edui-button-label {
        line-height: 17px;
    }
}
.userSuggest-modal {
    width: 800px;
    .modal-body_line {
        margin-bottom: 10px;
        color:#666;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .concat {
        color: #337ab7;
    }
}
#edui_fixedlayer {
    * {
        box-sizing: content-box;
    }
}
#edui1_message_holder {
    display: none;
}
.feedback-form {
    width: 50%; 
    margin-left: 15%;
    .feedback-form__check {
        display: inline-flex;
        align-items: center;
        > input[type=checkbox] {
            position: static;
            margin: 0;
        }
        > input[type=checkbox],>input[type=radio] {
            margin-right: 10px;
        }
    }
    .feedback-form__textarea {
        width:100%; 
        resize:vertical; 
        min-height: 100px; 
        max-height: 200px;
    }
    .feedback_form__other {
        border: none;
        border-bottom: 1px solid;
        width: 100px;
        outline: none;
        margin-left: 5px;
        &:disabled {
            background-color: #fff;
        }
    }
}