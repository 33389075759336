@color: #43a3fb;
@header: #fff;
@body: #f5f5f5;
@linkColor:#337ab7;
@fontColor:#999;
body{
    margin: 0;
    padding: 0;
    font-family:  Microsoft YaHei,'宋体' , Arial,Tahoma, Helvetica, "\5b8b\4f53", sans-serif;
    font-size: 14px;
    background-color: @body;
}
.defulatSystem{
    padding-top: 5px;
    span{
        color: #999;

    }
}
.selfSet{
    display: flex;
    align-items: center;
    height: 45px;
}
#notification, #noticeEmail{
    min-width: 1200px;
    height: 36px;
    line-height: 36px;
    position: relative;
    background: #fefced;
    font-size: 14px;
    border-bottom: 1px solid #c1c1c2;
    text-align: center;
    color: #ee733a;
    display: block;
}
#tamper_notification{
    min-width: 1200px;
    height: 36px;
    line-height: 36px;
    position: relative;
    background: #ffeded;
    font-size: 14px;
    border-bottom: 1px solid #c1c1c2;
    text-align: center;
    color: #ff6c60;
    display: block;
}
.cursorLink{
    cursor: pointer;
}
#notice_closeBtn, #noticeEmail_closeBtn, #tamper_notice_closeBtn{
    background: url(/Public/img/notice_closeBtn.png) center center no-repeat;
    width: 36px;
    height: 36px;
    z-index: 10000000;
    float: right;
    position: relative;
    right: 0px;
    cursor: pointer;

}

#neverNotice{
    float: right;
    color: #000;
    position: relative;
    right: 0px;
    display: flex;
    align-items: center;
}
ul,li{
    margin: 0;
    padding: 0;
    list-style-type: none;
}
label{
    font-weight: normal !important;
}
.languages {
    cursor: pointer;
}
a,a:hover,a:active,a:visited,a:link{
    text-decoration: none;
    color: @linkColor;

}
.bread-crumb{
    .last{
        color: @fontColor;
    }
}
textarea{
    max-width: 1000px;
}
#container{
    padding-left: 7px;
    padding-right: 7px;
    min-width:1000px;
    &.home-page {
        padding: 0;
    }
    #sidebar
    {
        float: left;
        width: 98.4%;
        height: 30px;
        line-height: 30px;
        position: relative;
        margin: 15px;
        text-align: center;
        //font-size: 13px;
        font-weight: 600;
        color: #666;
    }
    #copyright{
        width: 100%;
        float: right;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #999;
    }
    #content{
        min-height: calc(100vh - 58px);
        padding-top: 48px;
        &.home-page {
            padding: 0;
            min-height: calc(~"100vh - 65px");
            height: calc(~"100vh - 60px");
        }
    }
}

#header{
    height: 58px;
    background-repeat: repeat-x;
    //padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    min-width: 1000px;
    background-color:@header;
    box-shadow: 3px 3px 3px #e8e8e8;
    font-family: Arial!important;
    position: relative;
    //z-index: 1000;
    #list{
        float: left;
        position: relative;
        cursor: pointer;
        #enterDropdownMenu{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 58px;

        }
        .list-image{
            width: 26px;
            height: 26px;
            /* font-size: 1px; */
            margin-right: 28px;
        }
        #enter-dropdown {
            min-width: 44px;
            .dropdown-menu {
                min-width: 140px !important;
                left: 12px;
                top: 62px;
                z-index: 10000;
                position: absolute;
                li>a{
                  color: #6d6b6b;
                }
                .boxTop {
                    position: absolute;
                    left: 13px;
                    top: -11px;
                }

            }

        }
    }
    #logo{
        height: 58px;
        float: left;
        color: white;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: left;

        .logo{
            width: fit-content;
            padding-right: 4px;
            a {
                max-width: 380px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 58px;
                background-size: contain;
                cursor: pointer;
                background-repeat: no-repeat;
                background-position: left center;

            }
            a img
            {
                display: inline-block;
                //max-width: 190px;
                //max-height: 32px;
                //padding-top: 10px;
            }
        }
        .systemName{
            color: #6d6b6b;
            font-weight: bold;
            height: 44px;
            display: flex;
            align-items: flex-end;
        }
    }
    #topMenu{
        float: right;
        position: relative;
        height: 60px;
        line-height: 60px;
        //font-size: 13px;
        li{

            float: left;
            padding-left: 15px;

        }
        .last span{
            color: white;
        }
    }
    #language-menu{
        img{
            cursor:pointer;
        }
    }
    #goVnnox{
        float:right;
        position: relative;
        //font-size: 13px;
        margin-right: -22px;
        cursor:pointer;
        img{
            border: 1px solid #eee;
            padding-left: 4px;
            padding-right: 4px;
            padding-top: 4px;
            padding-bottom: 4px;
            border-radius: 4px;
        }
    }
    #topMenuFeature{
        float:right;
        position: relative;
        padding-right: 15px;
        //font-size: 13px;
        margin-right: -13px;
        li{
            float: left;
            padding-left: 10px;
            padding-right: 10px;

        }
    }
    #header-bottom{
        height: 48px;
        clear: both;
        padding-top: 10px;
        padding-left: 18px;
    }
    #header-menu{
        position: relative;
        height: 30px;
        padding-right: 14px;
        //font-size: 13px;
        .boxTop{
            position: relative;
            top: -15.5px;
            left: 140px;
        }

        #menulist{
            position: absolute;
            background-color: white;
            z-index: 1050;
            top: 50px;
            left: -118px;
            width:308px;
            padding: 15px 0 15px 10px;
            border-radius: 5px;
            line-height: normal;
            -webkit-background-clip: padding-box;
            background-clip: padding-box;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 10px;
            padding: 1px;
            border-width: 1px;
            border-style: solid;
            border-color: rgba(0, 0, 0, 0.2);
            border-image: initial;
            border-radius: 6px;
            padding-bottom: 10px;
            li{
                width: 100%;
                div{
                    display: inline-block;
                    width: 50%;
                    text-indent: 15px;
                    height: 30px;
                    line-height: 30px;
                    a{
                        width: 100%;
                    }
                }
            }
            p{
                margin-left: 5px;
                border-bottom: 1px solid #ddd;
                line-height: 25px;
                margin-bottom: 0px;
            }
            a  span{
                margin: 5px;
                color: #666666;
            }
            hr{
                margin: 10px 0 10px 0;

            }
        }

    }
    #depthMenu li{
        float: left;
        font-size: 13px;
        //padding-top:8px;
        color: #999;
    }

}
#dropdownMenu1{
    span{
        display: inline-block;
        text-align: center;
    }
    img:first-child{
        position: relative;
    }
    img:last-child{
        margin-bottom: 4px;
    }
}

.dropdown-menu{
    min-width: 0px !important;
    margin-top: -13px;
    margin-left: -22px;
    //width: 118px;
    /* height: 88px; */
    padding-bottom: 12px;
    li{
        padding-left: 0px !important;
        width: 100%;
        height: 30px;
        padding-top: 10px;
    }
    .boxTop{
        position: absolute;
        left: 51px;
        top: -11px;
    }

}
#guide-dropdown {
    width: 21px;
    position: relative;
    .guide-img {
        img {
            display: inline-block;
            width: 21px;
            height: 21px;
            cursor: pointer;
        }
    }
}
.guide-content {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    .guide-content-main{
        background:  url(/smart/build/images/envelope.png) center center no-repeat;
        background-size: 100% 100%;
        position: fixed;
        z-index: 99999;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 775.52px;
        height: 562.4px;
        line-height: 20px;
        padding: 35px 90px;
        color: #262626;
        border-radius: 4px;
    }
    .boxClose{
        position: absolute;
        top: -10px;
        right: -10px;
        width: 20px;
        height: 20px;
        background-size: 100% 100%;
        cursor: pointer;
        //hover旋转
        &:hover{
            transform: rotate(360deg);
        }
        transition: all 0.3s ease-in-out;
    }
    .guide-text{
        white-space: pre-line;
        line-height: 24px;
        margin-bottom: 0;
        &-li {
            display: flex;
            &::before {
                flex: none;
                content: '';
                margin-right: 9px;
                margin-top: 10px;
                width: 5px;
                height: 5px;
                background: #262626;
                border-radius: 50%;
            }
        }
    }
    .guide-btn {
        position: absolute;
        bottom: 35px;
        left: 50%;
        text-align: center;
        width: 200px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        transform: translateX(-50%);
        button {
            border: 1px solid #9EABE0;
            font-size: 12px;
            height: 32px;
            line-height: 16px;
            display: inline-block;
            border-radius: 4px;
        }
        button:last-child{
            background: #8694D1;
            color: #fff;
        }
        button:first-child {
            background: #ffffff;
            color: #1890FF;
            margin-bottom: 10px;
        }
    }
}
.language-menu{
    min-width: 0px !important;

}
.language-menu-list{
    min-width: 0px !important;
    margin-left: -50px;
    margin-top: -10px;
    width: 118px;
    height: 88px;
    li{
        padding-left: 0px !important;
        width: 100%;
        text-align: center;
        &.languages {
            text-align: left;
            a {
                padding-left: 15px;
                .isSelect {
                    display: inline-block;
                    width: 14px;
                    height: 14px;
                    margin-right: 5px;
                }
                .glyphicon {
                    font-size: 12px;
                }
            }

        }
    }
    .boxTop{
        position: absolute;
        left: 50px;
        top: -11px;
    }
}
.activeColor{
    color: @linkColor !important;
}

.modal_title{
    margin-left: 5px;
    margin-bottom: 0;
    margin-top: 15px;
    font-weight: bold;
}
.modal_label{
    margin: 5px;
    margin-right: 15px;
    color: #666666;
    margin-left: 0px;
    margin-bottom: 0px;
}

.modal_cus_body{
    padding-top: 0;
    padding-left: 19px;
}
.modal_area{
    float: left;
    margin-right: 102px;
    min-height: 104px;
    width: 185px;
}
.modal_input{
    margin-left: 5px !important;
    position: relative;
    top: 2px;
}
.clear{
    clear: both;
}
table input[type = 'checkbox']
{
    cursor : pointer;
}
.shade
{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    background-color: #000;
    z-index: 100;
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}
.dropdown-ico
{

    position: absolute;
    background-color: transparent;
    opacity: 1;
    height: 10px;
    width: 367px;
    top: 43px;
    left: -149px;
    z-index: 99999;
}
.dropdown-ico img
{
    position: absolute;
    left: 50%;
    margin-left: -7px;
}


.empty{
    padding: 0px !important;
}
#sys-tip{
    opacity:0.7;
    position: fixed; left: 50%; top: 20%;
    transform: translate(-50%, -50%);    /* 50%ä¸ºè‡ªèº«å°ºå¯¸çš„ä¸€åŠ */
    z-index:1051;
    background:#000;

    max-width:420px;
    line-height:20px;
    color:#fff;
    text-align:center;
    padding:10px 20px;


    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright:5px;
    -moz-border-radius-bottomleft:5px;
    -moz-border-radius-bottomright:5px;
    -webkit-border-top-left-radius:5px;
    -webkit-border-top-right-radius:5px;
    -webkit-border-bottom-left-radius:5px;
    -webkit-border-bottom-right-radius:5px;
    border-top-left-radius:5px;
    border-top-right-radius:5px;
    border-bottom-left-radius:5px;
    border-bottom-right-radius:5px;

    -moz-box-shadow: 0px 0px 9px #000000;
    -webkit-box-shadow: 0px 0px 9px #000000;
    box-shadow: 0px 0px 9px #000000;
}

#userSet-dropdown {
    //min-width: 84px;
    .dropdown-menu {
        width: 160px;
        left: -36px;
        margin-top: -9px;
        a{
            cursor: pointer;
            padding: 3px 15px;
        }
        //margin-top: ;
        .boxTop {
            left: 80px;
        }
    }
    
}
.return-previos {
    float: right;
    cursor: pointer;
}
.setContent-padding_left {
    padding-left: 47px;
}
.set-display_flex {
    display: flex;
}
.help-text_color {
   color: #22b122; 
}
.help-text_error {
   color:#666666; 
}
.modal-text_help {
    line-height: 28px;
    text-indent: 30px;
}
.modal-text_headerTitle {
    font-size: 21px;
}
.modal-table_width {
    margin: 10px;
    width: 98%;
}
.common-view {
    width: 100%;
    box-shadow: 0 2px 2px #dddddd;
    padding-bottom: 100px;
    background-color: white;
    min-height: 77vh;
    .common-view_content {
        padding: 20px 30px 0;
    }
}

.vnnox-junmp_info {
    cursor: default;
    position: absolute;
    border: 1px solid #eee;
    height: 60px;
    width: 228px;
    background-color: #fff;
    border-radius: 6px;
    left: -157px;
    top: -7px;
    line-height: 59px;
    text-indent: 12px;
    -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
    box-shadow: 0 5px 10px #e8e8e8;
    top: 56px;
}
.vnnox-junmp_colse {
    position: absolute;
    top: 5px;
    line-height: 10px;
    height: 15px;
    right: 10px;
    color: #000;
    opacity: 0.3;
    font-weight: 700;
    font-size: 20px;
    cursor: pointer;
}
.vnnox-junmp_guide {
    position: absolute;
    width: 15px;
    height: 15px;
    border: 1px solid #eee;
    transform: rotate(45deg);
    right: 31px;
    top: -9px;
    border-bottom: none;
    border-right: none;
    background: #fff;
}
    .Maskpre {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, .5);
        z-index: 3000;
        display: none;
    }
    .showModelpreview {
        width: 560px;
        height: 311px;
        background: url("/smart/build/images/message.png") no-repeat;
        color: white;
        position: relative;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: 15% auto;
        padding: 10px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    }
    .previewClose {
        text-align: right;
        padding: 5px 10px;
    }
    .previewClose span {
        cursor: pointer;
    }
    .preHeader {
        font-size: 20px;
        text-align: center;
        height: 30px;
        line-height: 30px;
        margin-bottom: 30px;
    }
    .preBody {
        padding: 0 20px;
        overflow: hidden;
    }
    .preBody p {
        margin: 0 !important;
        font-size: 14px !important;
        line-height: 1.42857143 !important;

    }
    .popover_hidden {
        display: none;
    }
    .header-message__bubble {
        position: absolute;
        font-size: 8px;
        top: -10px;
        left: 18px;
        color: #fff;
        height: 12px;
        line-height: 12px;
        text-align: center;
        border-radius: 5px;
        background-color: #ff6c60;
        padding-right: 4px;
        padding-left: 4px;
        z-index: 2;
    }