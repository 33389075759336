.device {
	padding-top: 35px;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 70px;
	.devie-group {
		margin-left: 20px;
		display: inline-block;
		label {
			display: inline-block;
			vertical-align: top;
			/*margin-right: 54px;*/
			color: #666;
			cursor: pointer;
		}
		input {
			cursor: pointer;
		}
	}
	.device-content {
		position: relative;
		margin-top: 30px;
	}

	.screen {
		
		display: block;
	}
	.screen-tip {
		display: none;
		background: #fff;
		border: solid 1px rgba(204, 204, 204, 1);
		position: absolute;
		
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.5);
		padding: 18px 20px;
		&>div:nth-child(not:last) {
			margin-bottom: 18px
		}
	}
}
