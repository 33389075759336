@import '../common/common';
.custom{
  .customDetail{
    padding: 10px 30px 10px 30px;
    .operateBth{
      position: relative;
      float: right;
      right: 13px;
      top: 14px
    }
    .operateBth button{
      margin-right:5px;
    }
    .ErrAlert{
      position: relative;
      top: -372px;
      left: 78px;
      z-index: 10000;
    }
    .module{
      display: inline-block;
      /* display: flex; */
      vertical-align: top;
      width: 33%;
    }
    .enterpriseUserPermissList {
      padding-left: 104px;
    }
    .enterpriseUserPermissList li{
      list-style: none;
      line-height: 27px;
      cursor: pointer;
    }
    .enterpriseUserPermissList span{
      padding-left: 5px;
    }
    .checkAll, a{
      cursor: pointer;
    }
    .radius_right{
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      line-height: 16px;
    }
    .radius_left{
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      line-height: 16px;
    }
    .userRole{
      height:162px;
      overflow: auto;

    }
    .chart {
      position: absolute;
    }
    .detectChart{
      width: 320px;
      height: 200px;
      div{
        left: 0% !important;
        top:  -13% !important;
      }
    }
    .blue {
      border-left: #241aff solid 1em;
      padding-left: .5em;
    }
    .green {
      border-left: #00ff01 solid 1em;
      padding-left: .5em;
    }
    .red {
      border-left: #fe0000 solid 1em;
      padding-left: .5em;
    }
    .virtualRed {
      border-left: #fe8081 solid 1em;
      padding-left: .5em;
    }
    .userRole ul {
      padding-left: 0;
      li {
        list-style: none;
        line-height: 25px;
        cursor: pointer;
        input{
          cursor: pointer;
        }
      }
    }
    #treeDemo{
      margin-left: -12px;
    }
    .titlePage{
      text-align: center;
      display: flex;
      flex-direction: column;
      img{
        margin: auto;
        margin-top: 120px;
      }
      strong{
        font-size: 38px;
        padding-top: 10px;
      }
    }

    .titlePage_left{
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-top: 220px;
      span{
        line-height: 25px;
      }
    }

    .titlePage_bottom{
      display: flex;
      flex-direction: column;
      /*margin-left: 40%;*/
      margin-top: 270px;
      div{
        text-align: left;
      }
      span{
        line-height: 25px;
      }
    }

    .title_top{
      margin-top: 60px;
    }
    .number{
      margin-bottom: 8px;
    }
    .pageDetailList ul{
      padding: 0;
      li{
        list-style: none;
        margin-bottom: 10px;
      }
    }
    .pageDetailList_top{
      display: flex;
      flex-direction: column;
      span{
        line-height:23px;
      }
    }
    .pie {
      width: 100px; height: 100px;
      border-radius: 50%;
      background: yellowgreen;
    }
    .paddingBanner{
      padding-top: 5px;
    }
    .pie_Out{
      display: flex;
      justify-content: flex-start;
      /* width: 100px; */
      padding: 0 !important;
      margin: 0 !important;
      border: none !important;
    }
    .picture-content.lineStyle {
      height: 20px;
    }
    .highcharts-button{
      display: none !important;
    }
    .legendBottom{
      position: relative;
      bottom: -114px;
      left: 90px;
      margin: 0 auto;
    }
    .highcharts-container{
      left: 50% !important;
      top: -10px !important;
    }
    .picture-content {
      width: 600px;
      height: 340px;
      position: relative;
      margin-left: 26px;
    }
    .picture-content > div {
      float: left;
      width: calc(50% - 1px);
      height: calc(50% - 1px);
      position: relative;
    }
    .picture-content > div > div {
      position: relative;
      width: 100%;
      height: 100%;
    }
    .picture-content > div .single {
      width: 690px;
      height: 340px;
    }
    .picture-content_img {
      width: 100%;
      height:100%;
    }
    .picture-content > div:nth-child(1), .picture-content > div:nth-child(3) {
      margin-right: 2px
    }
    .picture-content > div:nth-child(1), .picture-content > div:nth-child(2) {
      margin-bottom: 2px
    }
    .picture-img_header {
      position: absolute;
      top: 10px;
      left: 10px;
      color: #fff;
      font-size: 14px;
    }
    .picture-img_footer {
      position: absolute;
      bottom: 0px;
      padding: 2px 10px;
      background: rgba(0,0,0,.7);
      width: 100%;
      color: #fff;
      font-size: 14px;
      img {
        width: 18px;
      }
    }

    .picture-img_result {
      margin-left: 10px;
    }


  }
}

