.statusDescriptionContainer {
    width: 1155px;
    margin: 0 auto;
    padding: 50px 0 71px 0;
    a,img,p{
        margin:0;
        padding:0;
    }
    .clear{
        clear: both;
    }
    
    p{
        font-size: 15px
    }
    
    hr{
        margin-bottom: 37px;
        color: #cccccc;
    }
    .red_border{
        border-bottom: 4px solid #ff7878;
    }
    .yellow_border{
        border-bottom: 4px solid   #ffd237;
    }
    .green_border{
        border-bottom: 4px solid  #91d101;
    }
    .deepGreen_border{
        border-bottom: 4px solid   #5fc9af;
    }
    .statusDescriptionHeader{
        font-size: 35px;
        font-weight: bold;
    }
    .headPosition{
        position: absolute;
        top: 116px;
    }
    .topPosition{
        position: absolute;
        top: 198px;
    }
    .fourBoder{
        border-bottom-width: 4px;
    }
    .oneBoder{
        border-bottom-width: 1px;
    }
    
    
    .statusDescription{
        /*  min-height: 410px;*/
        margin-top: 35px;
        margin-bottom: 60px;
    }
    .statusDescriptionTop{
        margin-bottom: 16px;
    
    }
    .statusDescriptionTop_mainTitle{
        font-size: 30px;
        line-height: 1.3;
        font-weight: bold;
    
    }
    
    .statusDescriptionTop_subTitle{
        padding-top:10px;
        height: auto;
        line-height: 26px;
    
    }
}
