.workgroup{
        width: 100%;
        box-shadow: 0 2px 2px #dddddd;
        padding-bottom: 100px;
        background-color: white;
        min-height: 77vh;
        .main{
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 30px;
            .operateBth{
                position: relative;
                float: right;
                right: 13px;
                bottom: 15px;
                button{
                    margin-right: 5px;
                }
            }
            .row-common-margin-nova{
                margin-top: 15px;
            }
        }
        hr {
          margin-top: 8px;
          margin-bottom: 8px;
        }

        .nameError{
            position: absolute;
            top: -340px;
        }


}
.batch-workgroup {
    padding: 10px 30px 20px;
    .row-space {
        padding-top: 10px;
    }
    .order {
        cursor: pointer;
        .asc {
            position: relative;
            top: 3px;
        }
        .desc {
            position: relative;
            top: -2px;
        }
    }
}


