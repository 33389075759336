/* CSS Document */
.containerCamera{
  width: 99%;
  margin: 0 auto;
}
.title{
  font-size: 21px;
  text-align: left;
  line-height: 45px;
  /* color: #999; */
  border-bottom:3px solid #e4e4e4;
  color: #666666;
  strong{
    font-size: 21px;
  }
}
.cameraconfig{
  padding:15px 30px;
}
.cameraSetContent{
  width: 100%;
  display: flex;
  flex-direction: row;
}
.cameraDate,.getImageSet,.getImageSetImg{
  display: flex;
  flex-direction: column;
  width: 28%;
}
.FtpSetImg{
  display: flex;
  flex-direction: column;
  width: 35%;;
}
.cameraDate .row span,.getImageSetImg .row span,.FtpSetImg .row span,.cameraCopy .row span{
  color: #337ab7;
  font-size: 20px;
  line-height: 2;


}
.cameraDate img,.getImageSetImg img,.FtpSetImg img{
  cursor: pointer;
}
.cameraDate .row p{
  color: #666666;
  line-height: 22px;

}
.cameraCopy{
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  padding-left: 45px;
}
.cameraCopy  p{


}

.getImageSetImg .row p{
  color: #666666;
  margin-bottom: 2px;
}
.FtpSetImg .row small{
  font-size: 14px;
  line-height: 14px;
  word-break: break-all;
}
.FtpSetImg .row p{
  color: #666666;
  line-height: 0px;

}
.setContent{
  height: 110px;
}
.transitionImage{
  display: flex;
  align-items: center;
}
.setAlign div{
  display: inline-block;
  vertical-align: top;
}
.testCamera{
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.testCamera p{
  width: 36%;
  text-align: left;
  margin: 0 auto;
  padding-left:160px;
  line-height: 22px;
}
.smallTitle{
  border-bottom: 1px dashed #e4e4e4;
  line-height: 13px;
}
.smallTitle span{

  line-height: 45px;
}
.algin-center{
  margin: auto;
  font-size: 20px;
}
.btnTest:after{
  clear: both;
  content: "";
  display: block;
}
.btnTest button{
  min-width: 140px !important;
}
.btnTest{
  width: 80%;
  margin:0 auto;
  text-align: center;
}
.btnTest:first-child{
  margin-left:40%;
}
.btnTest button{
   float:left;
   margin-right: 30px;
  display: inline;
}
.errAlert1{

}
.clickViewConfig{
  text-align: center;
}
.clickViewConfig span{
  font-size: 18px;
}
.cameraDateCloseBtn{
  position: relative;
  left: 700px;
  top: 15px;
  z-index: 10000;
}
.getImageCloseBtn{
  position: relative;
  left: 768px;
  top: 16px;
  z-index: 10000;
}
.ftpSetCloseBtn{
  position: relative;
  left:740px;
  top: 14px;
  z-index: 10000;
}
.StepImg{
  margin-top: -6px;
  cursor: auto !important;
}
.modal-content{
  border: none;
}
.modal-content .suoLue{
  display: flex;
  /* align-items: flex-end; */
  justify-content: center;
}
.minWidth{
  min-width: 142px !important;
}
