.enterpriseinfo {
	width: 100%;
    box-shadow: 0 2px 2px #ddd;
    padding-bottom: 100px;
    background-color: #fff;
	min-height: 77vh;
	position: relative;
	.default-password {
		position: absolute;
		left:0;
		top: 0;
		z-index: -1;
	}
	.main {
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 30px;
	}
	.lable {
		height: 34px;
		line-height: 34px;
	}
	.lable-upload {
		height: 45px;
		line-height: 45px;
	}
	.setHelp {
		line-height: 30px;
    	margin-left: -12px;
	}
	.upload {
		line-height: 45px;
    	margin-left: -12px;
    	form {
    		position: relative;
    		background: #fafafa;
    		overflow: hidden;
    		width: 72px;
    		height: 35px;
    		input {
    			position: absolute;
			    font-size: 100px;
			    right: 0;
			    top: 0;
			    opacity: 0;
			    cursor: pointer;
    		}
    	}
	}
	.tileImg, .Board{
		cursor: pointer;
	}
	.copyBoard {
		margin-top: 8px;
		.text-left{
			overflow: hidden;
			white-space: normal;
			text-overflow: ellipsis;
          width: 89.5%;

        }
	}
	.browserLogo {
		background-image: url(/smart/build//images/browserLogoBg.png);
	    background-repeat: repeat;
	    height: 50px;
	    display: flex;
	    align-items: center;
	    padding-left: 15px;
	}
	.discription {
		margin-top: 8px;
		color: #999;
		font-size: 14px;
	}
	.emailTest {
		width: calc(~"100% - 125px");
		display: inline-block;
		&.enEnv {
			width: calc(~"100% - 146px");
		}
	}
	.sendEmail {
		margin-left: 10px;
		vertical-align: top;
	}
	.countryCode_phone{
		width:12% !important;
	}
	.info_phone{
		width: 21.32% !important;
	}
	.text-discription {
		color: #999;
		margin-top: 6px;
	}
	.treeDemo {
		margin-left: -12px;
	}
	.user-edit {
		vertical-align: middle;
		display: inline-block;
		&.check {
			margin: 0;
			margin-right: 5px;
			cursor: pointer;
		}
	}
}
.showPicture {
	padding-right: 17px;
	.sysNameCloseBtn {
		position: relative;
	    left: 749px;
	    top: 23px;
	    z-index: 1000;
	    cursor: pointer;
	}
	.temp {
		background-size: contain;
	    height: 400px;
	    background-repeat: no-repeat;
	}
	.modal-content {
		border: none;
	}
}
.defaultBtn {
	box-shadow: inset 0px -22px 34px -15px #999;
}
.confirmInfo {
	margin-top: 15px;
	margin-bottom: 30px;
}