//样式模版
//@color: #5db85b;
.nova_btn_set(@radius:4px,@backgroudColor:#fff,@color:#444,@borderColor:#dbdbdb,@backgroudImg:"/images/fixPsdBtnTip.png",@left:33px) {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left:@left;
    padding-right: 17px;
    border-radius:@radius;
    background-color: @backgroudColor;
    background-image: url(@{root}@backgroudImg);
    background-repeat: no-repeat;
    background-position: 15px center;
    color:@color;
    border:1px solid  @borderColor;
}
.a_set(@Color:@color){
  color:@Color !important;
  text-decoration: none;
  cursor: pointer;
}

.nova_btn_default{
    .nova_btn_set;
}
.nova_btn_active{
    .nova_btn_set(4px,@color,#fff,@color,"/images/addBtnTip.png");
}
.nova_btn_confirm{
    .nova_btn_set(4px,@color,#fff,@color,"",17px);
}
.nova_btn_cancel{
    .nova_btn_set(@backgroudImg:"",@left:17px);
}
.attr-link {
    .a_set;
}

.row-common-margin-nova {
    margin-top: 25px;
}
select {
    background-color: #fff;
    opacity: 1;
    cursor: pointer;
    &[disabled] {
        opacity: .5;
    }
}
.red-star-common {
    position: relative;
    left: 25px;
    margin-left: -20px;
    padding-top: 10px;
    color: #ef392b;
}
.div-label {
    //width: 180px;
    height: 34px;
    line-height: 19px;
    padding-top: 7px;
    padding-bottom: 10px;
    text-align: right;
    display: inline;
}
.left-label{
    height: 34px;
    line-height: 19px;
    padding-top: 7px;
    padding-bottom: 10px;
    display: inline;
}
.center-label{
    height: 34px;;
    line-height: 34px;
    padding-bottom: 10px;
    text-align: center;
    display: inline;
}
.form-input-nova {
    width: 75%;
    height: 34px;
    padding: 6px 12px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: inline;
}
@borderColor:#dce3f3;
.table-border {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd !important;
}

form-control[readonly], .form-control {
    background-color: #fff !important;
    opacity: 1;
    cursor: pointer;
    &[disabled] {
        opacity: .5;
    }
}
.form-control[disabled], fieldset[disabled]{
    background-color: #fff;
    opacity: 0.5;
    cursor: not-allowed;
}
.form-control[disabled], fieldset[disabled]{
    background-color: #fff;
    opacity: 0.5;
    cursor: not-allowed;
}
.radius_right {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    line-height: 15px;
}
.radius_left {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    line-height: 15px;
}
.red-star-common {
    position: relative;
    margin-left: -45px;
    color: #ef392b;
    height: 34px;
    line-height: 34px;
}
.btn_edit_icon {
    margin-left: -24px;
}
.redAlert {
    color: #ef392b;
}
.common-content {
    width: 100%;
    box-shadow: 0 2px 2px #ddd;
    padding-bottom: 100px;
    background-color: #fff;
    min-height: 77vh;
    .main {
        padding-right: 15px;
        padding-top: 30px;
        padding-left: 15px;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type="number"]{
    -moz-appearance: textfield;
}
.cursorPoint{
    cursor: pointer;
}
.disableOperate{
    opacity: 0.5;
    cursor: not-allowed !important;
}