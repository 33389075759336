@import '../common/common';
.brightness, .warnDetail {
    .emailServer-list, .log-list {
        thead, tbody {
            font-size: 14px;
        }
    }
    .email-receiver {
        word-break: break-word;
    }
    .email-detail_td {
        color: red;
    }
    .email-date_time {
        cursor: pointer;
    }
    .times-wrap {
        margin: 15px 0;
    }
    .suggest-message {
        padding-top: 5px;
        padding-bottom: 5px;
        height: 30px;
        .suggest-message_btn {
            cursor: pointer;
        }
    }
    .current, .log, .emailServer {
        padding-left: 30px;
        padding-right: 30px;
    }
    .leftSelect{
        width: 210px;
        height: 34px;
        outline: 0;
        border-radius: 4px;
        border: 1px solid #ddd;
        padding-left: 10px;
        float: left;
        margin-right: 13px;
    }
    .log{
        #js-log-Time{
            left: -300px;
        }
    }
    .brightness-Input:extend(.form-Input) {
    }
    .leftSelect{
        width: 210px;
        height: 34px;
        outline: 0;
        border-radius: 4px;
        border: 1px solid #ddd;
        padding-left: 10px;
        float: left;
        margin-right: 10px;
    }
    .brightness-export {
        float: right;
    }
    .setting {
        float: right;
        color: #333!important;
        margin-right: 30px;
        margin-top: -40px;
    }
    .emailServer {
        .default {
            display: inline-block;
            vertical-align: bottom;
        }
    }
    .fontColor{
        color: #337ab7;
        cursor: pointer;
    }
    .table>thead>tr>th {
        border-bottom: 2px solid #ddd;
        font-weight: bold;
    }
    .brightness-monitor {
        padding-top: 20px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .brightness-monitor_m {
        border-radius: 4px;
        border: 1px solid #ccc;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        outline: none;
        width: 70px;
    }
    .labelSet {
        line-height: 33px;
        span, input,select {
            display: inline-block;
            vertical-align: middle;
        }
        input {
            margin:0;
        }
        select {
            width: 70px;
            padding: 6px 12px;
            outline: none;
            border: 1px solid #ccc;
            font-size: 14px;
            border-radius: 4px;
        }
    }
    .brightness-monitor_add {
        cursor: pointer;
        margin-top: 5px;
        display: inline-block;
        span {
            font-size: 15px;
            color: #666;
        }
        
    }
    .probe-slider {
        width: calc(~"100% - 95px");
        display: inline-block;
        vertical-align: middle;
    }
    .probe-value {
        margin-left: 20px;
    }
    #probe {
        background: #f0ad4e;
    }
    .ui-slider-range {
        background: #5cb85c;
    }
    .monitor-slider {
        display: inline-block;
        width: 60%;
        margin-right: 10px;
        float: left;
    }
    .monitor-slider_val {
        width: calc(~"40% - 10px");
        float: left;
        text-align: center;
    }
    .monitor-slider_minval {
        margin-right: 40px;
    }
    .monitor-icon {
        color: #666;
        cursor: pointer;
        &:nth-child(1) {
            margin-right: 5px;
        }
    }
    .slider-val {
        float: left;
        height: 100%;
        &.val1 {
            background:#d9534f;
            width: 20%;
        }
        &.val2 {
            background:#5cb85c;
            width: 20%;
        }
        &.val3 {
            background:#d9534f;
            width: 20%;
        }
    }
    table {
        margin-bottom: 0;
    }
    .light-box {
        cursor: pointer;
    }
    .light-icon {
        display: inline-block;
        vertical-align: top;
        margin-right: 5px;
        font-size: 18px;
        line-height: 36px;
        color: #256aaa;
    }
    .light-text {
        display: inline-block;
        vertical-align: top;
        color: #676767;
        line-height: 36px;
    }
    .light-line {
        width: 100%;
        height: 0;
        border-top: 1px solid #eee;
        margin-bottom: 20px;
    }
    .val-text {
        font-size: 12px;
        color: #999;
    }
    .submit-wrap {
        margin-top: 20px;
    }
    .table-center {
        text-align: center;
    }
    .add-monitor_end {
        margin-top: 20px;
    }
    .add-monitor_text {
        color: #999;
        font-size: 12px;
        margin-top: 5px;
    }
    .email-break {
        word-break: break-word;
    }
}
#emailTitles {
    margin-top:20px;
    margin-bottom:20px;
    text-align:center !important;
    font-size: 24px;
    font-family: Microsoft YaHei,'宋体' , Arial,Tahoma, Helvetica, "\5b8b\4f53", sans-serif;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}
#table-container{
    overflow: scroll;
    height:50%;
    #emailTitle{
        margin-top:20px;
        margin-bottom:20px;
        text-align:center !important;
        font-size: 24px;
        font-family: Microsoft YaHei,'宋体' , Arial,Tahoma, Helvetica, "\5b8b\4f53", sans-serif;
        font-weight: 500;
        line-height: 1.1;
        color: inherit;
    }
    #statusAddress{
        margin-bottom:10px;
        width: 50%;
        float: left;
        line-height: 22px
    }
    .repairStatus{
        //background-size: cover;
        width: 61px;
        height: 61px;
        float: right;
        img{
            width: 100%;
            height: 100%;
        }
    }

}