@import '../common/common';
.custom{
  .customList{
    width: 100%;
    box-shadow: 0 2px 2px #dddddd;
    padding-bottom: 100px;
    background-color: white;
    min-height: 77vh;
    .main{
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 35px;
      .operateSearch{
        position: relative;
        left: 15px;
        margin-bottom: 14px;
      }
      .searchBtn{
        position: relative;
        left: 15px;
        margin-bottom: 14px;


      }
      .operateBth{
        position: relative;
        float: right;
        right: 40px;
        bottom: 0px;
        button{
          margin-right: 5px;
        }
        .report-icon--help {
          display: inline-block;
          margin-right: 20px;
          position: relative;
          top: 17px;
          width: 16px;
          height: 30px;
          cursor: pointer;
          background: url('/smart/build/images/helpTip.png') no-repeat;
        }
      }
      .col-xs-4{
        width: 440px;
      }
      .col-xs-2{
        width: 200px;
      }
      .noPadding{
        padding-left: 0;
      }
      .filterSearch {
        position: relative;
        left: -15px;
        top: 6px;
        color: #666;
        cursor: pointer;
      }
      .dtpicker-positions input {
        width: 192px;

      }

      .icon-search {
        background: url(/smart/build/images/search.png) no-repeat;
        display: inline-block;
        width: 13px;
        height: 16px;
        vertical-align: middle;
      }
    }


    .nameError{
      position: absolute;
      top: -340px;
    }
  }
  .editDetectTemplate{
    width: 100%;
    box-shadow: 0 2px 2px #dddddd;
    padding-bottom: 100px;
    background-color: white;
    min-height: 77vh;
    .main{
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 35px;
      .group{
        display: flex;
        flex-direction: row;
        width: 100%;
        .noPadding{
          padding: 0;
        }
        .left,.middle,.right{
          display: inline-block;
          vertical-align: top;
        }
        .left{

          padding-right: 10px;
        }
        .right{
          text-align: left;
          padding-left: 10px;
        }
        .middle{
          width: 70px;
        }
      }
    }
    .nameError{
      position: absolute;
      top: -340px;
    }
    .repairFormError{
      position: absolute;
      top: -340px;
      left: 0px;
    }
    .clearMP{
      margin: 0;
      padding: 0;
    }
  }
}

