.selectDay{
  width: 100%;
  display: inline-block;
  position: relative;
  input{
    width: 100%;
  }
  .daySelectTitle{
    height: 30px;
    padding: 5px;
    text-align: left;
    background: #eee;
  }
  .dayselect{
    position: absolute;
    top: 35px;
    left: 0;
    width: 60%;
    border: 1px solid #eee;
    z-index: 1;
  }
  .everyDays {
    padding-left: 16px;
    padding-top: 7px;
    background: #fff;
    &>li:hover{
      border: 1px solid #dcdcdc;
      background-color: #e8e8e8;
    }
    &>li{
      width: 27px;
      height: 27px;
      margin: 4px;
      list-style: none;
      display: inline-block;
      background-color: #f5f5f5;
      border-radius: 1px;
      text-align: center;
      line-height: 27px;
      cursor: pointer;
      border: 1px solid #e8e8e8;
    }
  }
}