
.bg2{
    background-color:#ff0000;
    width: 25px;
    height: 25px;
}

.bg3{
    background-color:#eaff00;
    width: 25px;
    height: 25px;
}

.bg4{
    background-color:#0000ff;
    width: 25px;
    height: 25px;
}

.bg5{
    background-color:#00ff0c;
    width: 25px;
    height: 25px;
}

.bg6{
    background-color:#ffa52f;
    width: 25px;
    height: 25px;
}

.bg7{
    background-color:#912fff;
    width: 25px;
    height: 25px;
}


.bg2:hover,.bg3:hover,.bg4:hover,.bg5:hover,.bg6:hover,.bg7:hover{
    border:3px solid #999;

}
.colorSelect{
    width: 110px;
    display: inline-block;
    vertical-align: top;


}
.showColor{
    width: 24px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    left: 6px;
    top: 8px;
    height: 19px;
    cursor: pointer;
    z-index: 1000;
}
.showColorBorder{
    display: inline-block;
    vertical-align: top;
    width: 41px;
    position: relative;
    left: -3px;
    cursor: pointer;
    z-index: 10;
}
.colorSelectBox{
    width: 285px;
    height: 100px;
    border: 1px solid #eee;
    position: absolute;
    left: 0px;
    top: 35px;
    z-index: 1005;
    background-color: #fff;

}
.colorHear{
    position: relative;
    width: 100%;
    height: 30px;
    background-color: #eee;
    padding-left: 10px;
    text-align: left;
}
.colorContent{
    position: relative;
    top: 0px;
    width: 100%;
    height: 66px;
    display: flex;
    flex-direction: row;
}
.colorCon{
    width: 27px;
    height: 27px;
    margin: 10px;
    border: 1px solid #eee;
    cursor: pointer;
}
.disabled{
    opacity: 0.5;
    cursor: not-allowed;
}
.colorSetBox{
    position: relative;
    display: inline-block;
    float: right;
    width: 76px;
   
}
.color-set-box {
    cursor: pointer;
}
.colorSetBox img{
    position: absolute;
    right: 1%;
}