#myAddress {
    #map {
        padding: 0;
        .labelSet {
            margin-top: 5px;
        }
        .container-fluid {
            padding: 0;
        }
        #addrinput1 {
            padding-top: 32px;
            padding-bottom: 32px;
        }
    }
    .map_wrap {
        position: relative;
        height: 400px;
    }
    #map_canvas2, #map_canvas {
        position: absolute;
        width:100%;
        height:400px;
        left: 0;
        top: 0;
        z-index:-1;
        &.active {
            z-index: 1;
        }
    }
    #addrSearchAndMap{
        position:absolute;
        margin-top: 10px;
        margin-left: 0px;
        margin-right: 0px;
        z-index: 9999;
        text-align: right!important;
        width: 97%;
    }

    #addrinput{
        margin-top:-60px;
        background:#CCC;
        opacity:0.9;
        margin-left:0;
        margin-right:0;
        height:60px;
        line-height:60px;
        color:#fff;
        position: relative;
        z-index:2;
    }
    #addrinput input{
        margin-top:12px;
    }
    .introjs-skipbutton{
        color: #000000;
    }
    .modal-footer {
    }
    .address-wrap_autosearch {
        border: 1px solid #C0C0C0;
        position: absolute;
        width: 420px;
        top: 46px;
        ul, li {
          padding: 0;
          margin: 0;
          list-style: none;
          background: #fff;
          color: #000;
        }
        li {
          line-height: 33px;
          color: #555;
          padding-left: 10px;
          cursor: pointer;
          &:hover {
            background: #ebebeb;
          }
        }
    }
  .btn-group>.btn-group:last-child:not(:first-child)>.btn:first-child, .btn-group>.btn:last-child:not(:first-child), .btn-group>.dropdown-toggle:not(:first-child){
    border-radius: 0px;
  }
  .btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child, .btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle, .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {

    border-radius: 0px;
  }

}
