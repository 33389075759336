@import '../common/common';
.faultAlarmConfig{
        width: 100%;
        box-shadow: 0 2px 2px #dddddd;
        padding-bottom: 100px;
        background-color: white;
        min-height: 77vh;
        .table-border{
          border-top: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          border-right: 1px solid #ddd;
          margin-bottom: 10px;
          td, th{
            border-left: 1px solid #ddd;

          }
          th{
            font-weight: bold;
          }
        }
        .main{
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 35px;
            .group{
                display: flex;
                flex-direction: row;
                width: 100%;
                .noPadding{
                    padding: 0;
                }
              select{
                width: 70px;
              }
                .left,.middle,.right{
                    display: inline-block;
                    vertical-align: top;
                }
                .left{
                  padding-right: 10px;
                }
                .right{
                  text-align: left;
                  padding-left: 10px;
                }
                .middle{
                  width: 70px;

                }
            }

              .checkSendMail{
                //text-align: center;
              }
              .detail{
                //text-align: center;
              }
              .temperature-slider {
                width: 75%;
                display: inline-block;
                vertical-align: middle;
                .slider-val {
                  float: left;
                  height: 100%;
                  &.val1 {
                    background:#d9534f;
                    width: 20%;
                  }
                  &.val2 {
                    background:#f0ad4e;
                    width: 20%;
                  }
                  &.val3 {
                    background:#5cb85c;
                    width: 20%;
                  }
                  &.val4 {
                    background:#f0ad4e;
                    width: 40%;
                  }
                }
              }
              .title_item {
                width: 220px;
              }
              #receive_voltage, #receive_temperature,#smartModule_temperature,#monitor_humidity,#monitor_voltage, #monitor_fanspeed{
                background: #f0ad4e;
              }
              .ui-slider-range{
                background: #5cb85c;
              }
              #monitor_fanspeed .ui-slider-range {
                background:#f0ad4e;
              }
              #monitor_fanspeed{
                background:#5cb85c;
              }
              .ui-slider-handle {
                font-size: 16px;
              }
              .threshold {

                &>span {
                  display: inline-block;
                  //vertical-align: middle;
                  text-align: right;
                  font-size: 13px;
                  min-width: 150px;
                }
              }

            }



        .introP{
            padding-top: 10px;
            color: #999;
            font-size: 13px;
            margin:0;
        }
        .nameError{
            position: absolute;
            top: -340px;
        }
        .repairFormError{
            position: absolute;
            top: -340px;
            left: 0px;
        }
        //.dtpicker-overlay{

        .clearMP{
          margin: 0;
          padding: 0;
        }
        .table-title{
          padding-bottom: 10px;
        }
}
