@import '../common/common';
.detectTemplate{
    .detectTemplateList{
        width: 100%;
        box-shadow: 0 2px 2px #dddddd;
        padding-bottom: 100px;
        background-color: white;
        min-height: 77vh;
        .main{
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 35px;
            .operateBth{
                position: relative;
                float: right;
                right: 13px;
                bottom: 15px;
                button{
                    margin-right: 5px;
                }
            }
        }


        .nameError{
            position: absolute;
            top: -340px;
        }
    }
    .editDetectTemplate{
        width: 100%;
        box-shadow: 0 2px 2px #dddddd;
        padding-bottom: 100px;
        background-color: white;
        min-height: 77vh;
        .main{
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 35px;
            .group{
                display: flex;
                flex-direction: row;
                width: 100%;
                .noPadding{
                    padding: 0;
                }
                .left,.middle,.right{
                    display: inline-block;
                    vertical-align: top;
                }
                .left{

                    padding-right: 10px;
                }
                .right{
                    text-align: left;
                    padding-left: 10px;
                }
                .middle{
                    width: 70px;
                }
            }
        }
        .nameError{
            position: absolute;
            top: -340px;
        }
        .repairFormError{
            position: absolute;
            top: -340px;
            left: 0px;
        }
        .clearMP{
            margin: 0;
            padding: 0;
        }
    }
}

