.warnVal, .manageTag {
    width: 100%;
    box-shadow: 0 2px 2px #ddd;
    padding-bottom: 100px;
    background-color: #fff;
    padding: 30px 30px 100px;
    min-height: 77vh;
    .warnVal-number, .manageTag-number {
    	padding-left: 10px;
	    text-align: center;
	    width: 50px;
    }
    .checkcolor {
    	background-color: #d9edf7;
    }
    .mustneed {
    	color: red;
    }
    .control-label {
    	line-height: 34px;
    }
    .control-label_text {
        min-width: 144px;
    }
    .description {
    	padding-left: 10px;
    	color: #999;
    }
    table>tbody>tr>td.cont {
    	padding-left: 20px;
    }
    .table-bordered>thead>tr>th {
        border-width: 1px;
    }
    .temperature-slider {
    	width: 75%;
    	display: inline-block;
    	vertical-align: middle;
    	.slider-val {
    		float: left;
    		height: 100%;
    		&.val1 {
    			background:#d9534f;
    			width: 20%;
    		}
    		&.val2 {
    			background:#f0ad4e;
    			width: 20%;
    		}
    		&.val3 {
    			background:#5cb85c;
    			width: 20%;
    		}
    		&.val4 {
    			background:#f0ad4e;
    			width: 40%;
    		}
    	}
    }
    .title_item {
    	width: 220px;
    }
    #temperature,#smartModule_temperature,#humidity,#voltage{
	    background: #f0ad4e;
	}
	.ui-slider-range{
	    background: #5cb85c;
	}
	#fanspeed .ui-slider-range {
	    background:#f0ad4e;
	}
	#fanspeed{
	    background:#5cb85c;
	}
	.ui-slider-handle {
		font-size: 16px;
	}
    .threshold {
    	
    	&>span {
		    display: inline-block;
		    //vertical-align: middle;
		    text-align: right;
		    font-size: 13px;
		    min-width: 150px;
		}
    }
}