.workgroup {
    .setting {
        float: right;
        color: #333!important;
        margin-right: 30px;
        margin-top: -40px;
    }
    .workgroup-cont {
        margin-top: 30px;
    }
}