.screen-cameral {
    background: #fff;
    height: 100vh;
    .screen-cameral_item {
        width: calc(~"100%/3 - 15px/3");
        float: left;
        height: 310px;
        margin-right: 5px;
        margin-bottom: 5px;
        .has-setted {
            position: relative;
            height: 100%;
            background: #555;
        }
        .no-setting {
            width: 100%;
            height: 100%;
            background: #000;
            p {
                margin: 0;
                width: 100%;
                padding-top: 20px;
                text-align: center;
                color: #fff;
            }
        }
        .grid {
            width: calc(~"50% - 1px ");
            height: calc(~"50% - 1px ");
            float: left;
            margin: 0.5px;
            &.nth-child(1), &.nth-child(2) {
                margin-top: 0;
            }
            &.nth-child(1), &.nth-child(3) {
                margin-left: 0;
            }
            &.nth-child(2), &.nth-child(4) {
                margin-right: 0;
            }
            &.nth-child(3), &.nth-child(4) {
                margin-bottom: 0;
            }
            img {
                width: 100%;
                height: 100%;
            }
        }
        .single {
            &:first-child {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
            }
        }
        .cameral-text {
            
            position: absolute;
            color: #fff;
            bottom: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            padding: 5px 10px 5px;
            background: rgba(0,0,0,.7);
            & > div {
                img {
                    margin-right: 5px;
                    width: 18px;
                }
                img, span {
                    vertical-align: middle;
                    display: inline-block;
                }
                .env {
                }

            }
            .env {
                margin-right: 5px;
            }
        }
    }
}
.imageDetail-content {
    position: relative;
    width: 900px;
}
.imageDetail-img {
    width: 100%;
}
.imageDetail-close {
    position: absolute;
    right: -15px;
    top: -20px;
    cursor: pointer;
}