.screen-monitor {
    position: relative;
    min-height: 870px;
    .overview-tab {
        position: relative;
        li {
            cursor: pointer; 
        }
    }
    .screen-info_content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        height: 40px;
        line-height: 40px;
        background: transparent;
        &.full-screen {
            top: -152px;
        }
    }
    .screen-info {
        z-index: 5;
        background: #f6f6f6;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        
        .info {
            margin-left: 15px;
            margin-right: 10px;
            font-size: 17px;
            color: #eba152;
            vertical-align: middle;
            display: inline-block; 
        }
        .close {
            margin-right: 10px;
            cursor: pointer;
            font-size: 17px;
            color: #000;
            opacity: .2;
            float: none;
            &:hover {
                opacity: .5;
            }
        }
        a {
            margin-left: 15px;
            margin-right: 40px;
            cursor: pointer;
        }
        span, a {
            display: inline-block;
            vertical-align: middle;
        }
        & > span, & > a {
            height: 40px;
        }
    }
    .screen-info_errorNum {
        color: #f79e3e;
    }
    .screen-monitor_buttons {
        position: absolute;
        top: 0;
        right: 0;
    }
    .btnRight {
        margin-top: 5px;
        margin-right: 12px;
        height: 32px;
        background: #F0F0F0;
        border: 1px solid rgb(208,208,208);
        outline-color: transparent;

    }
    .btn {
        outline-color: transparent !important;
    }
    .full-scrren_icon {
        color: #888;
        font-size: 23px;
        padding: 0 5px;
    }
    .out-screen {
        height: 40px;
        position: fixed;
        top: 0;
        right: 0;
        display: none;
        z-index: 1;
        line-height: 40px;
        background: #fff;
        border-radius: 4px;
        padding-left: 20px;
        padding-right: 15px;
        cursor: pointer;
        &.full-screen {
            display: block;
        }
        i {
            font-size: 23px;
            vertical-align: middle;
            color: #888;
        }
        span {
            display: inline-block;
            vertical-align: middle;
            margin-left: 4px;
            margin-top: -4px;
        }
    }

    .grid-content {
        min-height: 800px;
        padding: 20px 30px;
        position: absolute;
        top: 44px;
        bottom: 100px;
        left: 0px;
        right: 0px;
    }
    .no-setting {
        width: 100%;
        height: 100%;
    }
    .has-setting_bgColor {
        background: #fff;
        width: 100%;
        height: 100%;
        display: inline-block;
    }
    .has-setted {
        position: relative;
        width: 100%;
        height: 100%;
    }
    .cameral-name, .cameral-text {
        font-size: 12px;
        position: absolute;
        color: #fff;
        img {
            margin-right: 10px;
            display: inline-block;
            vertical-align: top;
            width: 18px;
        }    
    }
    .cameral-name {
        left: 5px;
        top: 5px;
        z-index: 3;
    }
    .cameral-text {
        left: 0;
        bottom: 0;
        z-index: 2;
        width: 100%;
        padding: 5px 10px;
        background: rgba(0,0,0,.7);
        .cameral-text_bottom {
            margin-top: 2px;
        }
    }
    .monitor-img {
        width: 100%;
        height: 100%;
    }
    .grid-content_box {
        width: 100%;
        height: 100%;
        position: relative;
        &.full-screen {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            min-height: 800px;
        }
        .grid {
            margin: 1px;
            /*display: inline-block;*/
            float: left;
            background: rgb(0, 0, 0);
            position: relative;
            p {
                color: #fff;
                text-align: center;
                margin: 0px;
                font-size: 12px;
                padding-top: 20px;
            }
        }
        .single {
            &:first-child {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
            }
        }
        .grid1 {
            width: 100%;
            height: 100%;
            margin: 0;
        }
        .grid4 {
            width: calc(~"50% -  1px");
            height: calc(~"50% -  1px");
            &:nth-child(1), &:nth-child(3) {
                margin-left: 0;
            }
            &:nth-child(2), &:nth-child(4) {
                margin-right: 0;
            }
            &:nth-child(1), &:nth-child(2) {
                margin-top: 0;
            }
            &:nth-child(3), &:nth-child(4) {
                margin-bottom: 0;
            }
        }
        .grid-children4 {
            margin: 0.5px;
            width: calc(~"50% -  0.5px");
            height: calc(~"50% -  0.5px");
            &:nth-child(1), &:nth-child(3) {
                margin-left: 0;
            }
            &:nth-child(2), &:nth-child(4) {
                margin-right: 0;
            }
            &:nth-child(1), &:nth-child(2) {
                margin-top: 0;
            }
            &:nth-child(3), &:nth-child(4) {
                margin-bottom: 0;
            }
        }   
        .grid9 {
            width: calc(~"100%/3 -  4px/3");
            height: calc(~"100%/3 -  4px/3");
            &:nth-child(1), &:nth-child(4), &:nth-child(7){
                margin-left: 0;
            }
            &:nth-child(3), &:nth-child(6), &:nth-child(9){
                margin-right: 0;
            }
            &:nth-child(1), &:nth-child(2), &:nth-child(3){
                margin-top: 0;
            }
            &:nth-child(7), &:nth-child(8), &:nth-child(9){
                margin-bottom: 0;
            }
        }
        .grid16 {
            width: calc(~"100%/4 -  6px/4");
            height: calc(~"100%/4 -  6px/4");
            &:nth-child(1), &:nth-child(5), &:nth-child(9), &:nth-child(13){
                margin-left: 0;
            }
            &:nth-child(4), &:nth-child(8), &:nth-child(12), &:nth-child(16){
                margin-right: 0;
            }
            &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4){
                margin-top: 0;
            }
            &:nth-child(13), &:nth-child(14), &:nth-child(15), &:nth-child(16){
                margin-bottom: 0;
            }
        }
    }
}
#errorInfo {
    .modal-dialog {
        width: 700px;
        color: #6e6e6e;
    }
    h3 {
        margin: 0;
        font-size: 14px;
        color: #a6a6a6;
        margin-bottom: 10px;
    }
    tr:last-child {
        th, td {
            border-bottom: 1px solid #ddd;
        }
    }
    th, td {
        padding-left: 10px;
        font-weight: normal;
    }
    th:first-child, td:first-child {
        padding-left: 14px;
    }
    .table_header {
        margin-bottom: 0;
        &.table {
            th {
                border-top: 1px solid #ddd;
                border-bottom: none;
                background: #f7f9fa;
                font-weight: normal;
            }
        }
        
    }
    tbody {
        font-size: 13px;
    }
    .table_body {
        
    }
    .table_cont {
        max-height: 140px;
        overflow-y: auto;
    }
    .modal-header {
        border-bottom: none;
        padding-bottom: 0;
    }
}
.roast-modal {
    .modal-dialog {
        width: 860px;
    }
    #buttonGroup {
        display: inline-block;
    }
    .btn {
        outline: none;
    }

    .btn-default.active {
        background-color: #265a88;
        border-color: #245580;
        color: #fff;
    }
    .roast-modal_space {
        margin-left: 160px;
        vertical-align: middle;
        margin-right: 25px;
        font-weight: 700;
    }
    .roast-modal_min {
        margin-left: 10px;
         vertical-align: middle;
    }
}
.screen-select {
    .modal-dialog {
        width: 860px;
    }
    .modal-body {
        min-height: 490px;
        height: 490px;
        padding: 0;
    }
    .screen-list_modal {
        width: 490px;
        height: 100%;
        float: left;
        background: #f7f9fa;
        padding: 20px 20px 20px 40px;
        .screen-search_modal {
            position: relative;
        }
        .screen-searchinput_modal {
            width: 100%;
            height: 30px;
            line-height: 30px;
            padding-left: 40px;
            border-radius: 4px;
            border: 1px solid #ddd;
        }
        .screen-searchicon_modal {
            position: absolute;
            left: 15px;
            top: 8px;
            color: #6d6d6d;
        }

    }
    .search-text {
        display: inline-block;
        width: 30px;
        vertical-align: top;
        float: left;
        input {
            cursor: pointer;
        }
    }
    .result-name{
        width: 260px;
    }
    .list-name{
        width: 210px;
    }
    .list-name, .result-name {
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .list-tag {
        display: inline-block;
        width: calc(~"100% - 250px");
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .content-listheader_modal {
        font-weight: 700;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .content-list_modal {
        height: 380px;
        overflow-y: auto;
    }
    .screen-result_modal {
        height: 425px;
        overflow-y: auto;
    }
    .select-header_modal {
        font-size: 16px;
        font-weight: 700;
        margin: 15px 0;
    }
    .item-list_modal {
        margin-bottom: 6px;
    }
    .screen-select_modal {
        width: calc(~"100% - 490px");
        height: 100%;
        float: left;
        padding: 0px 30px;
        .creen-selectitem {
            height: 34px;
            line-height: 34px;
            margin-bottom: 8px;
            background: #f7f9fa;
            padding: 0 15px;
            border-radius: 4px;
            cursor: move;
            i {
                float: right;
                color: #000;
                opacity: .2;
                font-size: 18px;
                margin-top: 7px;
                cursor: pointer;
                &:hover {
                    opacity: .5;
                }
            }
        }
        .creen-selectitem_close {
            font-size: 21px;
            color: #000;
            opacity: .2;
            line-height: 1;
            float: right;
            margin-top: 4px;
            cursor: pointer;
            &:hover {
                opacity: .7;
            }
        }
    }
}
.title-icon_monitor {
    display: inline-block;
    width: 4px;
    height: 14px;
    background: #7ba7e4;
    vertical-align: bottom;
    margin-right: 7px;
}
