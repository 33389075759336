.ui-front {
    z-index: 1002!important;
}
.email-message {
    z-index: 1001;
    display: none;
    width: 100%;
    height: 100%;
}
#header {
    &.home-header_fix {
        box-shadow: 3px 3px 3px #999;
    }
}

.homepage {
    width: 100%;
    height: calc(~"100vh - 60px");
    font-family: Arial;
    .loading-list {
        padding: 10px 20px;
        img {
            margin-right: 10px;
            vertical-align: middle;
        }
        * {
            font-size: 12px;
            color: #999;
        }
    }
    .info-window_wrap {
        max-height: calc(~"100vh - 175px");
        overflow-y: auto;
    }
    table {
        &.Word {
            margin-bottom: 0;
        }
    }
    .loading-swipe {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1001;
        cursor: default;
    }
    .email-notify-logo {
        width: 100%;
        height:62px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        background-color: #73AEFF;
        border-bottom: 7px solid #CCC;
    }
    .email-notify_addr {
        line-height: 30px;
        word-break: break-all;
    }
    .email-notify_hr {
        width: 100%;
        height: 0px;
        border: none;
        border-bottom: 1px solid #ccc;
    }
    .email-notify_foothr {
        height: 26px;
        width: 100%;
        line-height: 26px;
        border-bottom: 1px dashed #dedede;
    }
    .email-notify_notanswer {
        width: 100%;
        text-align: center;
        font-size: 14px;
        color: #999999;
        padding-top: 8px;
    }
    .email-detail_td {
        color: red;
    }
    .loading {
        position: absolute;
        z-index: 1000;
        width: 80px;
        height: 80px;
        left: 156px;
        top: 50px;
        line-height: 80px;
        border-radius: 10px;
        background: #ffffff url('/smart/build/images/loading.gif') center center no-repeat;
        opacity: 0.4;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            font-size: 10px;
            transform: scale(0.83);
            color:#222;
        }
    }
    
    .cards {
        float: left;
        width: 100%;
        min-height: 80px;
        margin-top: 30px;
        padding-top: 5px;
        padding-bottom: 5px;
        width: 394px;
        table {
            width: 100%;
            border: 0;
            border-collapse: collapse;
            margin-top: 10px;
            tr:hover {
                background: #fff;
            }
            td {
                padding-top: 4px;
                padding-bottom: 3px;
                border: 0;
                color: #f00;
                font-size: 14px;
                font-weight: bold;
                text-align:center;
                img {
                    width: 22px;
                    height: 22px;
                    vertical-align: middle; 
                }
            }
        }
        .led, .playhost {
            padding-bottom: 10px;
            &.chartajax {
                background: #e6e6e6 url(/smart/build/images/ajax_check.gif) center center no-repeat;
            }
            .table-header {
                width: 147px;
                height: 43px;
                margin-left: auto;
                margin-right: auto;
                font-size: 12px;
                line-height: 43px;
                text-align: center;
                margin-top: -22px !important;
                background: url('/smart/build/images/bg_table_head.png') center center no-repeat;
                img {
                    width: 30px;
                    height: 30px;
                    vertical-align: middle;
                }
            } 
        }
        .led {
            box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
            float: left;
            width: 160px;
            min-height: 100px;
            max-height: 300px;
            border: 1px solid #dcdcdc;
            border-radius: 5px;
            background: url('/smart/build/images/bg_table.png');
            background-repeat: repeat;
            .content {
                width: 159px;
                height: 230px;
                overflow-x: hidden;
                overflow-y: auto;
            }
        }
        .playhost {
            box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
            float: right;
            width: 200px;
            min-height: 100px;
            max-height: 300px;
            border: 1px solid #dcdcdc;
            border-radius: 5px;
            background: url('/smart/build/images/bg_table.png') repeat;
            table {
                td {
                    color: #333;
                    font-size: 12px;
                }
            }
            .content {
                width: 200px;
                height: 230px;
                overflow-x: hidden;
                overflow-y: auto;
            }
            .label-text {
                font-size: 12px;
                position: relative;
                top: -7px;
            }
            .disks-image {
                position: relative;
                top: -7px;
            }
            .playhost-disk {
                
                p {
                    display: block;
                    width: 90%;
                    height: 13px;
                    border-radius: 3px;
                    border: 1px solid #8c8c8c;
                    margin-bottom: 0;
                    &.text-info {
                        font-size: 12px;
                        border: none;
                        color: #000;
                        text-align: left;
                        font-weight: normal;
                        margin-bottom: 5px;
                    }
                }
                
                span {
                    display: block;
                    height: 13px;
                    background: url('/smart/build/images/bg_disc.png') repeat-x; 
                }
            }
            .playhost-text {
                text-align: left;
                
                p {
                    border-radius: 0px;
                    display: block;
                    width: 90%;
                    height: 10px;
                    border: 1px solid #8c8c8c;
                    margin-bottom: 0;
                    span {
                        display: block;
                        height: 8px;
                        background: #00ff00; 
                    }
                }
            }
        }
    }
    .sidebar {
        width: 300px;
        float: left;
        height: 100%;
        box-shadow: 3px 3px 3px #999;
        position: relative;
        z-index: 1001;
        background: #fff;
        .mask-loading {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            cursor: wait;
            z-index:1;
        }
    }
    
    .map-content {
        width: calc(~"100% - 300px");
        height: 100%;
        float: left;
        position: relative;
        box-shadow: inset 3px 3px 3px #999;
        background: #999;
        position: relative;
        overflow:hidden;
        .map-select {
            list-style: none;
            position: absolute;
            z-index: 999;
            top: 10px;
            right: 15px;
        }
        .map-select_item {
            background: #F2F2F2;
            cursor: pointer;
            font-size: 12px;
            float: left;
            padding: 5px 8px;
            border: 1px solid #CCC;
            font-family: Arial;
            &:nth-child(1) { 
                border-right: none;
               
            }
            &.active {
                background: #09C;
                color: #fff; 
            }
        }
        .tag-group {
            padding-right: 20px;
            padding-bottom: 20px;
            position: absolute;
            z-index: 1000;
            cursor: pointer;
            &.active {
                height: 100%;
            }
        }
        .tag-group_wrap {
            margin-left: 10px;
            margin-top: 10px;
            width: 50px;
            height: 50px;
            border-radius: 25px;
            background: #fff;
            background-image: url('/smart/build/images/label_tag.png') center center no-repeat;
        }
        .tag-group_container {
            float: left;
            max-height: 65%;
            padding-right: 10px;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 0;
            }
            &::-webkit-scrollbar-thumb {
                background: #CCC;
            }
        }
        .p-label {
            transition: All 0.3s ease;
            margin-left: 20px;
            margin-bottom: 5px;
            height: 30px;
            line-height: 30px;
            clear: both;
            cursor: pointer;
            color: #777;
            
        }
        .tag-item_lock {
            margin-left: -12px;
            margin-top: -3px;
        }
        .tag-item_defaut {
            font-size: 12px;
            padding-left: 15px;
            width: 160px;
            height: 30px;
            display:block;
            padding-right: 5px;
            background: #D5FFD5;
            border: 1px solid #fff;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            &:hover {
                border: 1px dotted #0F0;
            }
        }
        .info-window {
            position: absolute;
            width: 0;
            border: 1px solid #aaa;
            z-index: 999;
            right: 20px;
            top: 40px;
            box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.3);
            &.active {
                width: 450px;
                transition: width 0.1s ease;
            }
            &.unactive {
                width: 0;
                border: 0;
                transition: width 0.1s ease;
                overflow: hidden;
            }
        }
        .info-window_email {
            cursor: pointer;
            max-width: 135px;
        }
        .info-window_close {
            position: absolute;
            width: 20px;
            height: 20px;
            right: 10px;
            top: 13px;
            cursor: pointer;
            background: url('/smart/build/images/screen-close.png') center center no-repeat/16px;
            z-index: 1000;
        }
        .info-window__switch {
            position: absolute;
            right: 50px;
            top: 13px;
            cursor: pointer;
            user-select: none;
            > img {
                width: 16px;
                position: relative;
                top: -2px;
                left: 2px;
            }
        }
        .info-window_name {
            width: 90px;
            height: 100%;
            line-height: 44px;
            border-bottom: 3px solid #0f3;
            white-space: nowrap;
        }
        .email-success {
            color: green;
            font-weight: bold;
        }
        .email-error {
            color: red;
            font-weight: bold;
        }
        .info-window_txt {
            color: #000;
            // font-weight: 700;
            padding: 0 10px;
            display: inline-block;
            max-width: 194px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .info-window_header {
            border: 1px solid #aaa;
            background: #f1f1f1;
            height: 44px;
            width: 449px;
            border-top: none;
            border-left: none;
            padding-left: 4px;
        }
        .wrap-item {
            padding: 20px 28px;
            background: #fff;
            overflow: auto;
        }
        .wrap-item_header {
            overflow: hidden;
            width: 394px;
            * {
                font-size: 12px;
            }
            .header-name {
                float: left;
                font-weight: bold;
                color: #000;
            }
            .header-more {
                float: right;
                background: #7dc1ff;
                color: #fff;
                padding: 3px 8px;
                margin-bottom: 6px;
                border-radius: 5px;
                text-indent: 0;
                cursor: pointer;
            }   
        }

        .wrap-swiper {
            .btn-left {
                width: 50px;
                height: 50px;
                position: absolute;
                left: 0;
                background-color: rgba(0, 0, 0, 0.5);
                top: 50%;
                margin-top: -25px;
                color: white;
                cursor: pointer;
                text-align: center;
                line-height: 50px;
                font-size: 19px;
                border-radius: 4px;
                &.isdisabled {
                    background-color: rgba(144, 144, 144, 0.5);
                    cursor: default;
                }
                i {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    background: url('/smart/build/images/btnl.png') no-repeat center center;
                }
            }
            .btn-right {
                width: 50px;
                height: 50px;
                border-radius: 4px;
                position: absolute;
                right: 0;
                top: 50%;
                margin-top: -25px;
                color: white;
                text-align: center;
                line-height: 50px;
                cursor: pointer;
                font-size: 19px;
                background-color: rgba(0, 0, 0, 0.5);
                &.isdisabled {
                    background-color: rgba(144, 144, 144, 0.5);
                    cursor: default;
                }
                i {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    background: url('/smart/build/images/btnr.png') no-repeat center center;
                }
                
            }
        }
        .wrap-item_banner {
            position: relative;
        }
        .swiper {
            width: 392px;
            height: 200px;
            overflow: hidden;
            position: relative;
            margin: 0 auto;
            box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.5);
        }
        .swiper-list {
            height: 100%;
            width: 400%;
            position: absolute;
            left: 0;
            top: 0;
            transition: all 0.3s ease;
            li {
                width: 392px;
                height: 100%;
                float: left;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        
    }
    .sidebar-status {
        background: #fafafa;
        overflow: hidden;
        height: 40px;
        border: 1px solid #c9c9c9;
        &.wait {
            opacity: .3;
        }
    }
    .sidebar-status_item {
        float: left;
        padding-left: 8px;
        padding-right: 8px;
        color: #666;
        height: 38px;
        line-height: 38px;
        border-left: 1px solid #f0f0f0;
        cursor: pointer;
        position: relative;
        &.active {
            border-bottom: 3px solid #0F3;
            
        }
        &:not(.active):hover {
            border-bottom: 3px solid red;
        }
        .status-name {
            font-size: 12px;
        }
        .status-num {
            position: absolute;
            width: 12px;
            height: 12px;
            font-size: 12px;
            -webkit-transform: scale(0.75);
            color: #999;
            top: -8px;
            right: 0;
            &.status-mormal {
                color: #1abc9c;
            }
            &.status-offline {
                color: #95a5a6;
            }
            &.status-warning {
                color: #e67e22;
            }
            &.status-error {
                color: red;
            }
        }
    }
    .screen-list {
        height: calc(~"100vh - 100px");
        overflow-y: auto;
    }
    .screen-item {
        height: 85px;
        width: 100%;
        cursor: pointer;
        border-bottom: 1px solid #eeeeee;
        &:hover {
            background: rgb(241, 241, 241);
            .screen-item_name {
                text-decoration: underline;
            }
        }
        &.active {
            background: rgb(241, 241, 241);
        }
    }
    .screen-item_status {
        float: left;
        width: 55px;
        height: 55px;
        vertical-align: top;
        &.normal {
            background: url(/smart/build/images/bg_status.png) -165px 0 no-repeat;
        }
        &.error {
            background: url(/smart/build/images/bg_status.png) 0 0 no-repeat;
        }
        &.off {
            background: url(/smart/build/images/bg_status.png) -110px 0 no-repeat;
        }
        &.warning {
            background: url(/smart/build/images/bg_status.png) -55px 0 no-repeat;
        }
    }
    .screen-item_name {
        float: left;
        height: 30px;
        line-height: 30px;
        width: 228px;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        color: #555;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .screen-item_address {
        float: left;
        width: 228px;
        height: 25px;
        line-height: 15px;
        font-size: 12px;
        color: #999;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .screen-item_tag {
        width: 100%;
        height: 30px;
        float: left;
        line-height: 30px;
        font-size: 12px;
        color: #999;
        overflow: hidden;
        white-space: nowrap;
    }
    .tag-item {
        margin: auto 3px;
        padding: 0px 10px;
        background: #D5FFD5;
        font-size: 12px;
        font-family: Arial;
        max-width: 58px;
        display: inline-block;
        height: 21px;
        line-height: 20px;
        border: 1px solid #CCC;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        &:hover {
            box-shadow: 2px 2px 2px rgba(0,0,0,.3);
        }
    }
    .tag-item_color {
        
        &.label0 {
        color: #68acff;
        background: -webkit-gradient(linear, 0% 100%, 0% 0%, from(#ceeff8), to(#e3f7fd)) ;
        }
        &.label1 {
        color: #6b7792;
        background: -webkit-gradient(linear, 0% 100%, 0% 0%, from(#e4d4cc), to(#eedfd7)) ;
        }
        &.label2 {
        color: #6fa8ee;
        background: -webkit-gradient(linear, 0% 100%, 0% 0%, from(#d0ddeb), to(#e4ebf5)) ;
        }
        &.label3 {
        color: #6b7792;
        background: -webkit-gradient(linear, 0% 100%, 0% 0%, from(#bbdfd8), to(#e4f7f2)) ;
        }
        &.label4 {
        color: #6faaf3;
        background: -webkit-gradient(linear, 0% 100%, 0% 0%, from(#f0d7f5), to(#f6e7f9)) ;
        }
        &.label5 {
        color: #6b7792;
        background: -webkit-gradient(linear, 0% 100%, 0% 0%, from(#e6e3c6), to(#f0edd4)) ;
        }
        &.label6 {
        color: #6b7792;
        background: -webkit-gradient(linear, 0% 100%, 0% 0%, from(#ffd0cf), to(#ffd8d7)) ;
        }
        &.label7 {
        color: #6b7792;
        background: -webkit-gradient(linear, 0% 100%, 0% 0%, from(#c4f3b8), to(#d3f6cc)) ;
        }
        &.label8 {
        color: #6b7792;
        background: -webkit-gradient(linear, 0% 100%, 0% 0%, from(#fadeb3), to(#fee6be)) ;
        }
        &.label9 {
        color: #6b7792;
        background: -webkit-gradient(linear, 0% 100%, 0% 0%, from(#e6f5bd), to(#f1ffca)) ;
        }
    }
    .map-container {
        width: 100%;
        height: 100%;
        position: relative;
        &>div {
            position: absolute;
            top:0;
            right:0;
            bottom:0;
            left:0;
            z-index:-1;
            &.active {
                z-index:1;
            }
        }
    }
}
.nova-scroller::-webkit-scrollbar-thumb {
    background-color: #c7c7c7;
    border-radius: 3px;
}
.nova-scroller::-webkit-scrollbar-track-piece {
    background-color: transparent;
}
.nova-scroller::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
.home-search {
    float: right;
    position: relative;
    height: 60px;
    line-height: 60px;
    transition: 0.2s all;
    &:hover {
        .home-search_input {
            border: 1px solid #4d90fe;
        }
        
    }
    &.active {
        width: 250px;
    }
    &.unactive {
        width: 210px;
    }
    .search-clear {
        position: absolute;
        width: 20px;
        height: 28px;
        line-height: 29px;
        text-align: center;
        cursor: pointer;
        right: 39px;
        top: 15px;
        font-size: 16px;
        z-index: 1;
        font-family: Arial;
    }
    .home-search_input {
        display: inline-block;
        vertical-align: top;
        width: calc(~"100% - 35px");
        height: 27px;
        line-height:27px;
        padding: 5px 0px;
        font-size: 12px;
        padding-left: 63px;
        padding-right: 20px;
        color: #aaa;
        border: 1px solid #c0c0c0;
        border-radius: 4px;
        position: absolute;
        left: 0;
        top: 17px;
        
    }
    .search-button {
        background: url('/smart/build/images/search_all.png') no-repeat;
        display: inline-block;
        height: 17px;
        vertical-align: middle;
        width: 27px;
        background-position: right;
        cursor: pointer;
        position: absolute;
        right: 6px;
        top: 21px;
    }
    .home-search_type {
        position: absolute;
        height: 32px;
        line-height: 32px;
        cursor: pointer;
        top: 15px;
        left: 0px;
        .home-search_typename {
            font-size: 12px;
            color:#999;
            padding-left: 25px;
            &>span {
                font-size: 12px;
            }
        }
        .search-arrow {
            background: url('/smart/build/images/arrow_down.png') no-repeat;
            display: inline-block;
            width: 10px;
            height: 9px;
            vertical-align: middle;
        }
        
        .home-search_hover {
            position: absolute;
            z-index: 1000;
            border: 1px solid #c2c3c5;
            border-radius: 4px;
            padding-left: 18px;
            width: 73px;
            top: 30px;
            background: #fff;
            padding-top: 5px;
            padding-bottom: 5px;
            line-height: 25px;
        }
        .hover-item {
            cursor: pointer;
            color: #666;
            font-size: 12px;
            &:hover {
                color: #000;
                font-weight: bold;
            }
        }
    }
}
.homepage {

    .screen-container {
        position: absolute;
        z-index: 999;
        width: 0;
        right: 20px;
        top: 40px;
        background-color: #fff;
        box-shadow: -5px 5px 5px rgba(0, 0, 0, .3);
        transition: width .1s ease;
        overflow: hidden;
        .label-ellipsis {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis
        }
    }

    .screen-container--active {
        width: 900px;
    }

    .screen-title {
        border: 1px solid #dce3f3;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        padding: 0 24px;
        background: #f1f1f1;

        .screen-title__span {
            font-size: 15px;
            flex: 1 1 auto;
        }

        .screen-title__switch {
            margin-right: 27px;
            color: rgba(0,0,0,.65);
            cursor: pointer;
            user-select: none;
            > img {
                width: 16px;
                position: relative;
                top: -2px;
                left: 2px;
            }
        }

        .screen-title__img {
            cursor: pointer;
            width: 16px;
        }
    }

    .screen-body {
        height: calc(~"100vh - 210px");
        border: 1px solid #dce3f3;
        border-top: 0;
        display: flex;
        flex-flow: row nowrap;
        overflow-y: auto;
        .screen-body_canvas {
            position: relative;
        }
        .screen-body_tip {
            position: absolute;
            min-width:100px;
            height:36px;
            line-height:36px;
            padding-left: 10px;
            padding-right: 10px;
            background:rgba(255,255,255,1);
            box-shadow:0px 0px 8px 0px rgba(0,0,0,0.15);
            border:1px solid rgba(227,227,228,1);
            display: none;
        }
        .switch-s{
            width: 28px;
            height: 16px;
            position: relative;
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 0;
            input{
                display:none ;
            }
            .round{
                border-radius: 24px;
            }
            .slider{
                background: #ccc;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                transition: all .4s;
                cursor: pointer;
            }
            //小圆点
            .slider:after{
                content: '';
                width:12px;
                height:12px;
                display: block;
                border-radius: 50%;
                background-color: #FFFFFF;
                position: absolute;
                top: 2px;
                left: 2px;
                transition: all .4s;
            }
    
            //单选框选中后改变的样式
            input:checked+.slider{
                background: #2196F3;
            }
            input:checked+.slider:after{
                transform: translateX(12px);
            }
            
        }
        .title-switch {
            margin-bottom: 10px;
            .switch-item {
                margin-right: 12px;
            }
            .switch-name {
                vertical-align: middle;
            }
        }
        .switch-radio {
            cursor: pointer;
            margin: 0 24px 0 0;
            display: inline-flex;
            align-items: center;
            > span{
                margin-left: 6px;
            }
        }
        .screen-body_item {
            padding: 20px;
            padding-top: 15px;
            padding-bottom: 0;
            canvas {
                border: 1px solid #dce3f3;
                background: #F7F7F7;
                width: 100%;
                height: 466px;
            }
        }

        .screen-body__slider {
            width: 120px;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            &::after {
                content: '';
                display: inline-block;
                width: 1px;
                height: calc(~"100% - 34px");
                background-color: #dce3f3;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .slider__ul,
        .slider__li {
            margin: 0;
            padding: 0;
            list-style-type: none;
            float: unset;
        }

        .slider__ul {
            margin-top: 13px;
            flex: 1 1 auto;
        }

        .slider__li {
            color: rgba(0,0,0,.65);
            height: 46px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 25px;
            text-align: right;
            &:hover {
                color: rgba(0,0,0,.85);
            }
            &::after {
                content: '';
                display: inline-block;
                width: 2px;
                height: 38px;
                background-color: transparent;
                position: relative;
                right: -25px;
            }
            &.active {
                &::after {
                    background-color: #447ab2;
                }
            }
        }

        .screen-body__content {
            height: 100%;
            flex: 1 1 auto;
        }

        .screen-camera__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;
        }

        .screen-camera__wrap {
            width: 100%;
            height: 475px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f7f7f7;
            position: relative;
            user-select: none;

            .screen-camera__bar {
                width: 64px;
                height: 64px;
            }
        }

        .screen-camera__content {
            width: 715px;
            height: 443px;
            box-shadow: none;

            .swiper-list {
                li {
                    width: 715px;
                }
            }
        }

        .screen-player__wrap {
            flex: 1 1 auto;
            display: flex;
            flex-wrap: wrap;
            margin: 20px;
            width: 450px;

            .progress-item {
                width: 200px;
                margin-bottom: 24px;
                &:nth-child(even) {
                    margin-left: 32px;
                }
            }
            .progress-item__label {
                display: inline-block;
                margin-bottom: 3px;
            }

            .progress {
                height: 10px;
                margin-bottom: 0;
                border-radius: 8px;
                box-shadow: none;
            }

            .progress-bar {
                box-shadow: none;
                border-radius: 8px;
            }

            .progress-bar-success {
                background-color: #4ca9ff;
            }
        }

        .screen-player__header {
            margin-bottom: 16px;
            display: flex;
            justify-content: flex-end;
        }

        .screen-sendercard {
            padding: 18px 20px 0;
            &::after {
                content: '';
                position: relative;
                top: 5px;
                width: 100%;
                display: inline-block;
                border-top: 1px dashed #dce3f3;
            }
            .screen-sendercard__header {
                margin-bottom: 16px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .screen-sendercard__body {
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
                margin-bottom: 16px;

            }

            .screen-sendercard__title {
                display: inline-block;
                color: rgba(0,0,0,.65);
            }

            .screen-sendercard__item {
                min-width: 180px;
                position: relative;
                margin-bottom: 8px;
                +.screen-sendercard__item {
                    margin-left: 8px;
                }
            }

            .sendercard__card {
                width: 100%;
                background: #fff;
                border: 1px solid #e0e1e2;
                box-sizing: content-box;
                border-radius: 4px;
                +.sendercard__card {
                    margin-top: 16px;
                }
            }

            .card__title {
                height: 24px;
                background: #f7f7f7;
                color: rgba(0,0,0,.65);
                padding: 0 20px;
                > span {
                    font-size: 12px;
                }
            }

            .card__content {
                height: 36px;
                display: flex;
                align-items: center;
                padding: 0 20px;
                .card__img {
                    width: 24px;
                    +.card__img {
                        margin-left: 16px;
                    }
                }
            }

            .card__title--type {
                display: inline-block;
                margin: 0 -6px;
            }

            .sendercard__connect {
                position: absolute;
                top: 0;
                left: 0;

                .sendercard__connect--tomaster {
                    top: 55px; 
                    left: 69px; 
                    width: 49px;
                    position: absolute;
                }

                .sendercard__connect--toslave {
                    position: absolute;
                    width: 9px;
                    top: 57px; 
                    left: 69px;
                }
            }
        }
    }
}

.name-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.app-report {
    position: relative;
}

.report-oprate {
    position: absolute;
    right: 30px;
    top: 0;
    display: flex;
    height: 100%;
    align-items: center;
    .report-set {
        display: flex;
        align-items: center;
        position: relative;
    }
    .report-icon--help {
        position: relative;
        top: 7px;
        width: 16px;
        height: 30px;
        margin-left: 4px;
        cursor: pointer;
        background: url('/smart/build/images/helpTip.png') no-repeat;
    }
    .report-config {
        margin-left: 24px;
        color: #000 !important;
    }

}
.chat-list__warn {
    position: relative;
    top: -7px;
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: #ff6c60;
}
