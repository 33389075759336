@import '../common/common';
.detection {
    .setting {
      float: right;
      color: #333!important;
      margin-right: 30px;
      margin-top: -40px;
    }
    .main {
        padding-left: 30px;
        padding-right: 30px;
      .analysis{
        display: flex;
        justify-content: space-between;
        .left,.right{
          width: 49.5%;
          .content{
            width: 100%;
            display: flex;
            justify-content: center;
            .chart{
              height: 500px;
              width: 700px;
            }
          }
        }
      }
      .analysisTitle{
        display: flex;
        justify-content: space-between;
        .left,.right{
          width: 49.5%;
        }
      }
      .fontColor{
        color: #337ab7;
        cursor: pointer;
      }
      .table>thead>tr>th {
        border-bottom: 2px solid #ddd;
        font-weight: normal;
      }
    }


}
