.table-striped {
    table-layout: fixed;
    thead{
        .sorting_asc{
            padding-left: 18px;
            background-image:url("../images/sort_asc.png");
            background-repeat:no-repeat;
            background-position: center left;
            cursor: pointer;

        }
        .sorting_desc{
            padding-left: 18px;
            background-image:url("../images/sort_desc.png");
            background-repeat:no-repeat;
            background-position: center left;
            cursor: pointer;
        }
        .sorting{
            padding-left: 18px;
            background-image: url("../images/sort_both.png");
            background-repeat:no-repeat;
            background-position: center left;
            cursor: pointer;
        }
    }
    tbody{
        a{
            text-decoration: none;
            color: #337ab7;
        }
    }
}
