.btn-nova {
  border-width: 1px;
  border-style: solid;
  border-color: #ddd;
  font-size: 12px;
  background-color: #fff;
  color: #666;
}

.btn-check-2-nova {
  padding-top: 3px !important;
  padding-bottom: 2px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.btn-check-3-nova {
  padding-top: 5px !important;
  padding-bottom: 4px !important;
  padding-left: 21px !important;
  padding-right: 21px !important;
}

.btn-nova:hover,
.btn-nova:active,
.btn-nova:visited {
  /*background-color: #fff;*/
  /*color: #666;*/
  background-color: #5cb95c;
  color: #fff;
  background-image: none;
}


/*li .btn-nova:hover,*/
/*li .btn-nova:active,*/
/*li .btn-nova:focus {*/
  /*background-color: #5cb95c;*/
  /*color: #fff;*/
/*}*/
.btn-success-nova {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 17px;
  padding-right: 17px;
  border-radius: 3px;
  color: #fff;
  background-image: -webkit-linear-gradient(top,#5cb85c 0,#419641 100%);
  background-image: -o-linear-gradient(top,#5cb85c 0,#419641 100%);
  background-image: -webkit-gradient(linear,left top,left bottom,from(#5cb85c),to(#419641));
  background-image: linear-gradient(to bottom,#77cc51 0,#52a451 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5cb85c', endColorstr='#ff419641', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  background-repeat: repeat-x;
  border-color: #64c264;
}

.btn-success-nova:hover,
.btn-success-nova:active,
.btn-success-nova:visited {
  background-color: #5cb95c;
  color: #fff;
}
.btn-success-nova:focus{
    outline: none;
    color: #fff;
}
.btn-default-nova {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 17px;
  padding-right: 17px;
  border-radius: 3px;
  background-color: #f0f0f0;
  color: #444;
  background-image: -webkit-linear-gradient(top,#fff 0,#e0e0e0 100%);
  background-image: -o-linear-gradient(top,#fff 0,#e0e0e0 100%);
  background-image: -webkit-gradient(linear,left top,left bottom,from(#fff),to(#e0e0e0));
  background-image: linear-gradient(to bottom,#fff 0,#e0e0e0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe0e0e0', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  background-repeat: repeat-x;
  border-color: #dbdbdb;
  border-color: #ccc;
}
.btn-default-nova,
.btn-success-nova
{
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(0,0,0,.075);
}

.btn-default-nova:hover,
.btn-default-nova:active,
.btn-default-nova:visited
{
  /*background-color: #f0f0f0;*/
  /*color: #666;*/
  background-color: #5cb95c;
  color: #fff;
}

/*li .btn-default-nova:hover,*/
/*li .btn-default-nova:active,*/
/*li .btn-default-nova:focus*/
/*{*/
  /*background-color: #5cb95c;*/
  /*color: #fff;;*/
/*}*/

.btn-addmore-nova {
  padding-top: 3px;
  padding-bottom: 2px;
  padding-left: 17px;
  padding-right: 17px;
}

.btn-week-nova {
  padding-top: 4px;
  padding-bottom: 3px;
  padding-left: 11px;
  padding-right: 11px;
}

.btn-filter-nova {
  border-radius: 3px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 11px;
}

.btn-clearall-nova {
  border-radius: 3px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 34px;
  padding-right: 34px;
  font-size: 11px;
}

.btn-tablectrl-nova
{
  margin-right: 10px;
}
.btn-default-nova:disabled {
  color: #bfbfbf;
  background-color: #fafafa;
}