.reportSet{
  .dtpicker-button{
    font-size: 15px !important;
  }
  .row{
    .EmailTipsP{
      color: #999;
      font-size: 12px;
      position: relative;
    }
    .last-class-rows{
      margin-top: 0 !important;
    }
    .setOpcity{
      opacity: .5;
    }
    .setBackground{
      background: #eee;
    }
    .TimeSetTip{
      position: absolute;
      top: 8px;
      right: -10px;
      cursor: pointer;
    }
    select,input {
      //width: 40%;
      display: inline-block;
    }
  }
  .reportSet_baseinfo, .reportSet_screenSetting{
    padding: 0 30px;
  }
  .selectDay{
    width: 100%;
    display: inline-block;
    position: relative;
    input{
      width: 100%;
    }
    .daySelectTitle{
      height: 30px;
      padding: 5px;
      text-align: left;
      background: #eee;
    }
    .dayselect{
      position: absolute;
      z-index: 1;
      top: 35px;
      left: 0;
      width: 60%;
      border: 1px solid #eee;
    }
    .everyDays {
      padding-left: 16px;
      padding-top: 7px;
      background: #fff;
      &>li:hover{
        border: 1px solid #dcdcdc;
        background-color: #e8e8e8;
      }
      &>li{
        width: 27px;
        height: 27px;
        margin: 4px;
        list-style: none;
        display: inline-block;
        background-color: #f5f5f5;
        border-radius: 1px;
        text-align: center;
        line-height: 27px;
        cursor: pointer;
        border: 1px solid #e8e8e8;
      }
    }
  }
  .reportSet_screenSetting{  //屏体关联配置
    tbody tr:first-child td{
      border-top: none !important;
    }
    .clearsBottom{
      margin-bottom:0 !important;
      border-top: 1px solid #ddd;
    }
    tbody.settingTable  {
      display:block;
      max-height:390px;
      overflow-y:scroll;
    }
    tr.selecteds {
      background-color: #d9edf7 !important;
    }
    thead, tbody.settingTable  tr {
      display:table;
      width:100%;
      table-layout:fixed;
    }

    .marginTops{
      margin-top: 25px;
    }
    .lableTip {
      margin-top: 15px;
      font-weight: normal;
      color: #000 !important;
      p {
        display: inline-block;
        vertical-align: top;
        font-weight: bold;
        margin: 0 0 10px;
      }
      span {
        display: inline-block;
        vertical-align: top;
        font-weight: normal;
        margin-left: 25px;
        color: #999;
        font-size: 12px;
      }
    }
  }
}
