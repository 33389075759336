
@charset "utf-8";
/* CSS Document */
.login{

body,button, input, select, textarea,h1 ,h2, h3, h4, h5, h6 { font-family: Microsoft YaHei,'宋体' , Arial,Tahoma, Helvetica,  "\5b8b\4f53", sans-serif;}
html{height:100%;}
// body{
	
// 	height:100%;
// 	}
#registerpage{
	// display: flex;
    // align-content: center;
    // flex-wrap: wrap;
	// justify-content: center;
	// flex-direction: row-reverse
	div{
		width: 100%;
		text-align: center;
	}
	.spanInfo {
		font-family: Microsoft YaHei,'宋体' , Arial,Tahoma, Helvetica,  "\5b8b\4f53", sans-serif;
		padding-top:12px;
		line-height:30px;
		font-size: 28px;
	}
	.glyphicon{
		display: flex;
    align-content: center;
    flex-wrap: wrap;
	justify-content: center;
	
	}
	.glyphicon::before{
		font-size: 48px;
		font-family: 'Glyphicons Halflings';
	}
}

.login-contents{ //语言选择和页脚容器样式
    position: absolute;
    min-height: 100%;
	top:0;
	padding-bottom:60px;
	width: 100%;
    min-width: 1024px;
	height: 100%;
	min-height: 100vh;
	background:#4f6772 url(/smart/build/images/login-page-bg.png) repeat-x;
}
.ischrome{
	top: 36px !important;
	
	min-height:  calc(~"100vh - 36px") !important;
}
.isEnStaly{
	width: 166px;
}
.login-headers{
    position: absolute;
	top: 0;
    width: 70%;
	left: 0;
    right: 0;
	margin: auto;
	min-width: 1024px;
    display: flex;
    justify-content: flex-end;
    font-size: 22px;
    color: white;
    height:40px;
 	line-height:40px;
}
.login-foots{
    position: absolute;
    width: calc(~"100% - 160px");
    height: 28px;
    margin: 0 80px;
    line-height: 28px;
    color: #fff;
    font-size: 13px;
    text-align: center;
    bottom: 0;
	margin-bottom: 15px;
	.login-currentNode{
		position: absolute;
		right: 11%;
		cursor: pointer;
		img{
			border-radius: 50%		
		}
	}
}
.login-languages_text{
	margin-right: 20px;
	position: relative;
    cursor: pointer
}
.login-languages_texts{
    display: inline-block;
    margin-left: 10px;
    font-size: 16px;
}
#loginform{
	padding:12px;
}
.login-icons{
	top: 4px !important;
	 display:none; color: rgb(239,57,43);
	  font-size: 20px; 
}
.submit-btn{
	background-color:#527291;
	 margin-top:4px;
}
.login-tip{
	margin-top:2px;
	  min-height:27px;
}
.layer-header{
	min-width: 300px;
	min-height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	img{
		//width: 300px;
		max-width: 300px;
		height: 64px;
		float:left;
	}
	.login-img_logo{
		margin: 0 30px;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center bottom;
		max-width: 320px;
		height: 64px;
		display: flex;
		justify-content: center;
		.login_text{
			display: flex;
			align-items: flex-end;
			padding-left: 4px;
			font-weight: bold;
		}
	}
	img.register-img_logo{
		margin-top:25px;
		margin-left:20px;
	}
	.register-img_text{
		float:left;
		margin-top:55px;
		color:#868686;
		font-size:20px;
	}
}
.layer-header::after{
	content: "";
	display: block;
	clear: both;
}
.layer-close{
	width: 1000px !important;
	height: 622px !important;
	position: relative;
	.login-img_logo{
		margin: 30px auto;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center bottom;
		max-width: 320px;
		height: 64px;
		display: flex;
		justify-content: center;
		.login_text{
			display: flex;
			align-items: flex-end;
			padding-left: 4px;
			font-weight: bold;
		}
	}
	.close-image{
		width: 432px;
		position: absolute;
		top: 152px;
		left: 284px;
	}
	.close-content{
		width: 912px;
		position: absolute;
		top: 320px;
		left: 44px;
	}
	.close-text{
		margin: 16px auto 0 auto;
		width: 912px;
		font-size: 16px;
		font-weight: 500;
		line-height: 28px;
		text-align: center;
		a{
			font-size: 16px;
		}
	}
	.close-text:last-of-type{
		margin-top: 0px;
	}
	.close-btn{
		margin:40px auto;
		width: 400px;
		height: 48px;
		font-size: 18px !important;
		font-weight: 600;
	}
}
.layer-close::after{
	content: "";
	display: block;
	clear: both;
}
.login-select_language{
	position: absolute;
    top: 45px;
    left: 0px;
    z-index: 1060;
    max-width: 276px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857;
    text-align: left;
    white-space: normal;
    background-color: rgb(255, 255, 255);
    background-clip: padding-box;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 10px;
    padding: 1px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.2);
    border-image: initial;
	border-radius: 6px;
	li{
		min-height: 20px;
		padding:10px 0 10px 32px;
		margin-bottom: 0;
		font-weight: 400;
		cursor: pointer;
		color: #333;
		position: relative;
		display: block;
		min-width: 118px;
		height: 34px;
	}
	li:first-child{
		font-size: 16px!important;
		padding: 8px 14px;
		margin: 0;
		background-color: #f7f7f7;
		border-bottom: 1px solid #ebebeb;
		border-radius: 5px 5px 0 0;
		font-weight: 500;
        line-height: 1.1;
	}
	li:first-child:after{
		position: absolute;
		top: -11px;
		border-width:0 11px 11px;
		margin-left: -10px;
		content: " ";
		left: 50%;
		display: block;
		width: 0;
		height: 0;
		border-color: transparent;
		border-bottom-color: #fff;
		border-style: solid;
	}
	.language-select:before {
		content: "\e013";
		position: absolute;
		left: 10px;
	}
}
@media screen and (max-width:1300px) {
	.layer-close{
		transform: scale(0.8);
	}
}
.help-block{
	color:#999;
	margin-top: 10px;
	margin-bottom: 10px;
	}

#container{
	min-height:100%;  
    position:relative; 
    div#content{  
       
       } 
	}
#notification{
	min-width: 1024px;
	height: 36px;
	line-height: 36px;
	background: #fefced;
	font-size: 14px;
	border-bottom: 1px solid #c1c1c2;
	text-align: center;
	color: #ee733a;
	// display: none;
}
#notice_closeBtn{
	width: 36px;
	height: 36px;
	z-index: 10000000;
	position: fixed;
	right: 0px;
	cursor: pointer;

}

tr{
	cursor:pointer;
	}
tbody tr:hover{
	background:#f5f5f5;
	}
td{
	vertical-align:middle!important;
	}
	
.hide{
	display:none;
	}
hr{
	border:0;
	background-color:#ddd;
	height:1px;
	}
	
#ajax-container{
	display:none;
	opacity:0.7;
	width:120px;
	height:120px;
	position: fixed;
	z-index:1051;
	left:50%;
	margin-left:-60px;
	top:20%;
	background:#000;
	-moz-border-radius-topleft: 5px;
	-moz-border-radius-topright:5px;
	-moz-border-radius-bottomleft:5px;
	-moz-border-radius-bottomright:5px;
	-webkit-border-top-left-radius:5px;
	-webkit-border-top-right-radius:5px;
	-webkit-border-bottom-left-radius:5px;
	-webkit-border-bottom-right-radius:5px;
	border-top-left-radius:5px;
	border-top-right-radius:5px;
	border-bottom-left-radius:5px;
	border-bottom-right-radius:5px;
	
	-moz-box-shadow: 0px 0px 9px #000000;
	-webkit-box-shadow: 0px 0px 9px #000000;
	box-shadow: 0px 0px 9px #000000;
	}
#ajax-container img{
	width:60px;
	height:60px;
	margin-top:10px;
	margin-left:30px;
	}

#ajax-container p#sys-text{
	color:#fff;
	text-align:center;
	line-height:40px;
	}
	
#sys-tip{
	opacity:0.7;
    position: fixed; left: 50%; top: 20%;
    transform: translate(-50%, -50%);    /* 50%为自身尺寸的一半 */
	z-index:1051;
	background:#000;
	
	max-width:420px;
	line-height:20px;
	color:#fff;
	text-align:center;
	padding:10px 20px;
	
	word-break:break-all;
	
	-moz-border-radius-topleft: 5px;
	-moz-border-radius-topright:5px;
	-moz-border-radius-bottomleft:5px;
	-moz-border-radius-bottomright:5px;
	-webkit-border-top-left-radius:5px;
	-webkit-border-top-right-radius:5px;
	-webkit-border-bottom-left-radius:5px;
	-webkit-border-bottom-right-radius:5px;
	border-top-left-radius:5px;
	border-top-right-radius:5px;
	border-bottom-left-radius:5px;
	border-bottom-right-radius:5px;
	
	-moz-box-shadow: 0px 0px 9px #000000;
	-webkit-box-shadow: 0px 0px 9px #000000;
	box-shadow: 0px 0px 9px #000000;
	}

/*浮动框*/
.layer-server{
	margin: 0 auto !important;
	margin-top: 100px !important;
	border-radius: 25px;
    background-color: rgba(250,250,250,.102);
    width: 1000px;
    padding: 30px 40px 44px 42px;
    color: #fff;
    margin-bottom: 30px;
    position: relative !important;
    z-index: 10;
    height: auto !important;
}
.layer{
    position: absolute; 
    bottom: 0;
	right:0;
	top:0;
	left: 0;
	margin: auto;
	z-index:100;
	// margin-left: -210px;
    // margin-top: -170px;
	background:#e3e8eb;
	width:420px;
	height:360px;
	line-height:20px;
	color:#333;
	padding:10px 20px;
	-moz-border-radius-topleft: 30px;
	-moz-border-radius-topright:30px;
	-moz-border-radius-bottomleft:30px;
	-moz-border-radius-bottomright:30px;
	-webkit-border-top-left-radius:30px;
	-webkit-border-top-right-radius:30px;
	-webkit-border-bottom-left-radius:30px;
	-webkit-border-bottom-right-radius:30px;
	border-top-left-radius:30px;
	border-top-right-radius:30px;
	border-bottom-left-radius:30px;
	border-bottom-right-radius:30px;
	}
.layer form.bigForm{
	width:420px !important;
	margin-left:auto;
	margin-right:auto;
	padding-top:20px;
	}
	.deleteShow{
		text-shadow: none !important;
	}
.layer form.bigForm button.btn-type.active{
	background:#8da2b7;
	border-color: #869aae;
	}
.login-min-height{
	min-width: 265px;
	//width: 330px;
	height: 64px;
	float: left;
}
.login-min-height-max{
	min-width: 260px;
	//width: 350px;
	height: 64px;
	float: left;
}
.layer input, .btn-lg, select.input-lg{
	font-size:14px!important;
	}
	.layer-register{
		width: 709px;
		height: fit-content;
		// margin-top: 5%
		// margin-left: -354.5px;
    // margin-top: -390px;
	}
/*
浮动框中提示内容
*/
p.help-block span{
	font-size:14px;
	}
.success-info{
	color:#6ac451;
	}
.error-info{
	color:red;
	}
	#password{
		margin-top:16px;
	}
#register{
	// margin-top:5px;
	 width: 350px;
	//  margin-left: -354.5px;
    // margin-top: -390px;
	  margin-right: auto;
	   margin-left: auto;
	   div.login_solid{
		border-right: 1px solid #337AB7;
		 height: 16px;
		  float: right;
		   margin-top: 2px;
		    margin-right: 8px;
	   }
	   .findPassword{
		float:right;
		   cursor: pointer;
	   }
	   .login_registers{
		float:right;
		 margin-right:8px;
		   cursor: pointer;
	   }
}
button#register-btn{
	margin-top:15px;
	background-color:#527291;
	}
button#register-btn:hover{
	background-color:#375f85;
	}

// #login-header{
// 	width:1200px;
// 	height:40px;
// 	line-height:40px;
// 	overflow:hidden;
// 	margin-left:auto;
// 	margin-right:auto;
// 	color:#fff;
// 	font-size:16px;
// 	text-align:right;
// 	padding-right:22px;
// 	}
#login-header a{
	color:#fff;
	font-size:16px;
    text-decoration:none;
    cursor: pointer;
	}
div.popover{
	color:#333;
	}
h3.popover-title{
	font-size:16px!important;
	}
#login-header a:hover{
	color:#fff;
	font-size:16px;
	text-decoration:none;
	}
#login-header span{
	font-size:22px;
	}

	
#footer{
	position:absolute;
	width:100%;
	margin-left:auto;
	margin-right:auto;
	
	height:28px;
	line-height:28px;
	color:#fff;
	font-size:13px;
	text-align:center;
	bottom:0;
	margin-bottom:15px;
	}
.changeNode{
    padding-right: 389px;
    float: right;
    cursor: pointer;
}
.footerText{
    padding-left: 436px;
}
.login-modal-body span{
    padding: 7px;
}
.modal-body a{
    color: #000;
}
.modal-body a:hover{
    color:#337ab7
}
.modal-dialog {
    width: 441px;

}
.phoneButton {
	height: 46px;
}
#sysCode {
	width: calc(~'100% - 180px');
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}
#sysCode.findPassword {
	width: 105%;
}
.isNone{
	display: none !important;
}
.marginBottoms{
	margin-bottom: 10px;
}
#VerifyCode {
	width: 170px;
	height:43px;
	display: inline-block;
	cursor: pointer;
}
.loadingStyle{
	width: auto !important;
	height: 14px !important;
}
.loadingStyless{
	width:700px;
	height:43px;
}
.flex-center{
	display: flex;
    align-items: center;
}
.istop{
	margin-top: 10px;
}
#verCode, #verCodePhone {
	font-size: 14px;
}
#verCode, #verCodePhone {
	width: calc(~'100% - 180px');
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
}
#verCode + img, #verCodePhone + img {
	width: 170px;
	cursor: pointer;
}
#verityCodeModel .help-block, #verityCodePhoneModel .help-block {
	margin-bottom: 0;
}
.globe-icon{
    top:4px !important;
}
}
.flex-spans{
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: flex-start;
	span {
		width: 30%;
		margin-bottom: 10px;
		text-align: left;
		img{
			border-radius: 50%;
			border: 1px solid #eee;
		}

	}
}
#noticeModal{
	.noticeModal_call{
		padding-bottom: 10px;

	}
	.noticeModal_content{
	text-indent: 30px;
	}
	a {
		color: #337ab7;
	}

}
