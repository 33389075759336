.message-log {
	padding: 20px;
	.page-area {
		height: 40px;
	}
	.message-nodate {
		text-align:center;
	}
	.message-header {
    	font-size: 20px;
    	margin-bottom: 8px;
	}
	.message-content {
		padding-left: 18px;
	}
	.message-title {
		border-top: 1px solid #ddd;
		padding-top: 25px;
	}
	.message-title {
		img, span {
			display: inline-block;
			vertical-align:middle;
		}
	}
	.message-version {
		color: #337ab7;
	    font-size: 16px;
	    font-weight: bold;
	}
	.message-time {
		color: #337ab7;
		font-size: 14px;
	}
}