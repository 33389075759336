.nv-picker {
  position: absolute;
  line-height: 1.4;
  z-index: 2000;
  font-size: 14px
}

.nv-picker .nv-picker__body {
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.20);
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.20);
  padding: 0;
  margin: 0;
  min-width: 80px;
  min-height: 40px;
  color: rgba(0,0,0,0.65);
}

.nv-picker .nv-picker__arrow {
  background-color: #fff;
  width: 12px;
  height: 12px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  display: block;
  border-color: transparent;
  border-style: solid;
}

.nv-picker[x-placement=top-end] .nv-picker__arrow,.nv-picker[x-placement=top-start] .nv-picker__arrow,.nv-picker[x-placement=top] .nv-picker__arrow {
  bottom: -4px;
  box-shadow: 3px 3px 7px rgba(0,0,0,.07)
}

.nv-picker[x-placement=bottom-end] .nv-picker__arrow,.nv-picker[x-placement=bottom-start] .nv-picker__arrow,.nv-picker[x-placement=bottom] .nv-picker__arrow {
  box-shadow: -2px -2px 5px rgba(0,0,0,.06);
  top: -6px;
}

.nv-picker[x-placement=left-end] .nv-picker__arrow,.nv-picker[x-placement=left-start] .nv-picker__arrow,.nv-picker[x-placement=left] .nv-picker__arrow {
  right: -4px;
  box-shadow: 3px -3px 7px rgba(0,0,0,.07)
}

.nv-picker[x-placement=right-end] .nv-picker__arrow,.nv-picker[x-placement=right-start] .nv-picker__arrow,.nv-picker[x-placement=right] .nv-picker__arrow {
  left: -4px;
  box-shadow: -3px 3px 7px rgba(0,0,0,.07)
}

.nv-picker[x-placement=bottom-start] .nv-picker__arrow,.nv-picker[x-placement=top-start] .nv-picker__arrow {
  left: 16px
}

.nv-picker[x-placement=bottom] .nv-picker__arrow,.nv-picker[x-placement=top] .nv-picker__arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg)
}

.nv-picker[x-placement=bottom-end] .nv-picker__arrow,.nv-picker[x-placement=top-end] .nv-picker__arrow {
  right: 16px
}

.nv-picker[x-placement=left-start] .nv-picker__arrow,.nv-picker[x-placement=right-start] .nv-picker__arrow {
  top: 16px
}

.nv-picker[x-placement=left] .nv-picker__arrow,.nv-picker[x-placement=right] .nv-picker__arrow {
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg)
}

.nv-picker[x-placement=left-end] .nv-picker__arrow,.nv-picker[x-placement=right-end] .nv-picker__arrow {
  bottom: 16px
}
.nv-picker--popover .nv-picker__body {
  min-height: 32px;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.20) !important;
  border: 1px solid rgba(0,0,0,0.20);
  background-color: #fff;
  color: rgba(0,0,0,0.65);
  border-radius: 5px;
}

.nv-picker--popover .nv-picker__arrow {
  border: 1px solid rgba(0,0,0,0.20);
  z-index: 1;
}

.nv-picker--popover .nv-popover {
  position: relative;
  min-height: 30px;
  z-index: 5;
  background-color: #fff;
  padding: 16px;
  line-height: 18px;
  border-radius: 5px;
}