.demainIntro {
    padding-right: 30px;
    padding-left: 30px;
    margin-top: -32px;
    padding-top: 10px;
    font-family: Microsoft YaHei,'宋体' , Arial,Tahoma, Helvetica, "\5b8b\4f53", sans-serif;
    min-height: 950px;
    margin-right: auto;
    margin-left: auto;
    overflow-x: hidden;
    .ErrAlert{
        position: relative;
        top: -372px;
        left: 78px;
        z-index: 10000;
    }
    .phoneErrAlert{
        position: relative;
        top: -339px;
        left: 78px;
        z-index: 10000;
    }
    .radius_right{
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        line-height: 15px;
    }
    .radius_left{
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        line-height: 15px;
    }
    .labelSet{
        line-height: 30px;
    }
    .persionalSet{
        margin-left: -34px;
    }
    .persionalSet button{
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
    }
    .link{
        padding-top: 5px;
        line-height: 30px;
    }
    .Board{
        padding-top: 10px;
        float: right;
        cursor: pointer
    }

    .pictureUrl{
        background-size: contain;
        background-image: url('../../images/sysNameDetail.png')
    }
    .getTemp1ImageCloseBtn{
        position: relative;
        top: 23px;
        left: 749px;
        z-index: 1000;
    }
    .getTemp2ImageCloseBtn{
        position: relative;
        top: 23px;
        left: 749px;
        z-index: 1000;
    }
    .getTemp3ImageCloseBtn_en{
        position: relative;
        top: 23px;
        left: 749px;
        z-index: 1000;
    }.getTemp3ImageCloseBtn_zn{
         position: relative;
         top: 23px;
         left: 749px;
         z-index: 1000;
     }
    .modal-content{
        border: none;
    }

    .logoAlert{
        margin-left: 4px;
        margin-top: 5px;
        color: #999;

    }
    .radius{
        border-radius: 50%;
        border: 1px solid #ccc;
        width: 50px;
        height: 50px;
    }
    .radius span{
        display: flex;
        justify-content: space-around;
        color: #337ab7;
        font-size: 14px;
        font-weight: bold;
    }
    .onlineStyle{
        display: flex;
        line-height: 49px;
    /*    border-left: 1px solid #ccc;
        margin-left: 40px;*/
    }
    .onlineStyle a{
      cursor: pointer;
    }
    .onlineStyle ul {
        line-height: 28px;
        list-style-type: lower-alpha;
        margin-left: -5px;
     }
    .onlineStyle ul li{
    }
    .title {
        font-size: 21px;
        text-align: left;
        line-height: 45px;
        /* color: #999; */
        border-bottom: 3px solid #e4e4e4;
        color: #666666;
    }
    .alertWarn{
        border-top: 1px solid #ccc;
        padding-top: 10px;
        color: #999;
    }
    .alertImg{
        display: flex;
        justify-content: center;
    }
    .modal-dialog{
        width: 800px;
    }
    .tempLine{
        height: 20px;
        border-left: 1px solid #ccc;
        margin-left: 25px;
    }
    .leftLine{
        border-left: 1px solid #ccc;
        margin-left: 40px;
    }
    .alertWarnNomal{
        color: #999;
        line-height: 5px;
    }
    .firstSpan{
        width: 50px;
        height: 50px;
    }
    .temp1{
        background-size: contain;
        height: 400px;
        background-repeat: no-repeat;
    }
    .temp2{
        background-size: contain;
        height: 400px;
        background-repeat: no-repeat;
    }
    .temp3{
        background-size: contain;
        height: 400px;
        background-repeat: no-repeat;
    }  
}

