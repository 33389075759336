.realtime {
    padding-left: 30px;
    padding-right: 30px;
    .realtime-set {
        float: right;
        top: -7px;
        position: relative;
        padding: 6px 12px;
        color: #;
        color: #333;
    }
    .realtime-status_icon {
        width: 24px;
        height: 24px;
        display: block;
        float: right;
        &.status_0 {
            background: url('/smart/build/images/status-bg.png') no-repeat -120px 0;
        }
        &.status_1 {
            background: url('/smart/build/images/status-bg.png') no-repeat -72px 0;
        }
        &.status_3 {
            background: url('/smart/build/images/status-bg.png') no-repeat -47px 0;
        }
        &.status {
            background: url('/smart/build/images/status-bg.png') no-repeat  0;
        }
    }
    .realtime-info {
        overflow: hidden;
        margin-bottom: 5px;
    }
    .realtime-label, .realtime-text {
        float: left;
    }
    .realtime-label {
        width: 50px;
        text-align: right;
    }
    .realtime-text {
        width: calc(~"100% - 50px");
        padding-left: 10px;
    }
    .realtime-set_inline {
        input, span, select {
            display: inline-block;
            vertical-align: middle;
            margin: 0;
        }
    }
}
.realtime-history {
    .search {
        vertical-align: top;
    }
    .btn-color_def {
        color: #444;
        float: right;
    }
    .list {
        margin-top: 20px;
    }
    .asc, .desc, .both {
        padding-left: 20px;
        background-repeat: no-repeat;
        background-position: center left;
        cursor: pointer;
    }
    .asc {
        background-image: url('../images/sort_asc.png');
    }
    .desc {
        background-image: url('../images/sort_desc.png');
    }
    .both {
        background-image: url('../images/sort_both.png');
    }
    .number {
        width: 50px;
    }
}