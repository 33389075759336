@import '../common/common';
.role{
    .roleList{
        width: 100%;
        box-shadow: 0 2px 2px #dddddd;
        padding-bottom: 100px;
        background-color: white;
        min-height: 77vh;
        .main{
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 30px;
            .operateBth{
                position: relative;
                float: right;
                right: 24px;
                //bottom: 15px;
                button{
                    margin-right: 5px;
                }
            }
            .list{
                padding-top: 15px;
            }
            .leftBottom {
                //bottom: 15px;
            }
        }


        .nameError{
            position: absolute;
            top: -340px;
        }

    }
    .editRole{
        width: 100%;
        box-shadow: 0 2px 2px #dddddd;
        padding-bottom: 100px;
        background-color: white;
        min-height: 77vh;
        .main{
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 35px;
            .parentModule{
              height: 34px;
              line-height: 34px;
               label{
                padding-left: 3px;
              }
            }
            .module{
                display: inline-block;
                /* display: flex; */
                vertical-align: top;
                width: 33%;
                padding-left: 15px;

                li{
                    list-style: none;
                    line-height: 27px;
                    cursor: pointer;
                    label{
                      padding-left: 3px;
                    }
                    .subModule{
                        padding-left: 15px;
                    }
                }
            }
            .group{
                display: flex;
                flex-direction: row;
                width: 100%;
                .noPadding{
                    padding: 0;
                }
                .left,.middle,.right{
                    display: inline-block;
                    vertical-align: top;
                }
                .left{

                    padding-right: 10px;
                }
                .right{
                    text-align: left;
                    padding-left: 10px;
                }
                .middle{
                    width: 70px;
                }
            }
        }
        .nameError{
            position: absolute;
            top: -340px;
        }
        .repairFormError{
            position: absolute;
            top: -340px;
            left: 0px;
        }
        .clearMP{
            margin: 0;
            padding: 0;
        }
    }
}

