.customSetList-tip{
  border: 1px solid #fff1c4;
  margin: 0 auto;
  padding: 12px;
  background-color: #fff9e6;
   p {
     color: #9d7437;
     margin: 0;
     font-size: 12px;
   }
}
.customSetList-btn{
  .col-xs-4{
    padding: 0 !important;
  }
  margin: 15px 0;
  .col-xs-8{
    padding: 0 !important;
    display: flex;
    justify-content: flex-end;
  }
  button {
    margin-left: 10px;
  }
}
 .clearsBottom {
  margin-bottom: 0 !important;
}