.ad-gallery {
  width: 600px;
}
.ad-gallery, .ad-gallery * {
  margin: 0 auto;
  padding: 0;
}
  .ad-gallery .ad-image-wrapper {
    width: 100%;
    height: 630px;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
  }
    .ad-gallery .ad-image-wrapper .ad-loader {
      position: absolute;
      z-index: 10;
      top: 48%;
      left: 48%;
      border: 1px solid #CCC;
    }
    .ad-gallery .ad-image-wrapper .ad-next {
      position: absolute;
      right: 0;
      top: 0;
      width: 10%;
      height: 100%;
      cursor: pointer;
      display: block;
      z-index: 100;
    }
    .ad-gallery .ad-image-wrapper .ad-prev {
      position: absolute;
      left: 0;
      top: 0;
      width: 10%;
      height: 100%;
      cursor: pointer;
      display: block;
      z-index: 100;
    }
    .ad-gallery .ad-image-wrapper .ad-prev, .ad-gallery .ad-image-wrapper .ad-next {
      /* Or else IE will hide it */
      /*background: url(../images/non-existing.jpg)*/
    }
      .ad-gallery .ad-image-wrapper .ad-prev .ad-prev-image, .ad-gallery .ad-image-wrapper .ad-next .ad-next-image {
        background: url(../images/ad_preTop.png);
        width: 44px;
        height: 70px;
        display: none;
        position: absolute;
        top: 47%;
        left: 0;
        z-index: 101;
      }

      .ad-gallery .ad-image-wrapper .ad-next .ad-next-image {
        background: url(../images/ad_nextTop.png);
        width: 44px;
        height: 70px;
        right: 0;
        left: auto;
      }
    .ad-gallery .ad-image-wrapper .ad-image {
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;
      z-index: 9; 
    }
      .ad-gallery .ad-image-wrapper .ad-image a img {
        border: 0;
      }
      .ad-gallery .ad-image-wrapper .ad-image .ad-image-description {
        position: absolute;
        bottom: 0px;
        left: 0px;
        padding: 7px;
        text-align: left;
        width: 100%;
        z-index: 2;
        background: url(../images/opa75.png);
        color: #000;
      }
      * html .ad-gallery .ad-image-wrapper .ad-image .ad-image-description {
        background: none;
      }
        .ad-gallery .ad-image-wrapper .ad-image .ad-image-description .ad-description-title {
          display: block;
        }
  .ad-gallery .ad-controls {
    height: 20px;
  }
    .ad-gallery .ad-info {
      float: left;
    }
    .ad-gallery .ad-slideshow-controls {
      float: right;
    }
      .ad-gallery .ad-slideshow-controls .ad-slideshow-start, .ad-gallery .ad-slideshow-controls .ad-slideshow-stop {
        padding-left: 5px;
        cursor: pointer;
      }
      .ad-gallery .ad-slideshow-controls .ad-slideshow-countdown {
        padding-left: 5px;
        font-size: 0.9em;
      }
    .ad-gallery .ad-slideshow-running .ad-slideshow-start {
      cursor: default;
      font-style: italic;
    }
  .ad-gallery .ad-nav {
    width: 100%;
    position: relative;
  }
    .ad-gallery .ad-forward, .ad-gallery .ad-back {
      position: absolute;
      top: 0;
      height: 100%;
      z-index: 10;
    }
    /* IE 6 doesn't like height: 100% */
    * html .ad-gallery .ad-forward, .ad-gallery .ad-back {
      height: 100px;
    }
    .ad-gallery .ad-back {
      cursor: pointer;
      left: -20px;
      width: 13px;
      display: block;
      background: url(../images/ad_scroll_backLeft.png) 0px 22px no-repeat;
    }
    .ad-gallery .ad-forward {
      cursor: pointer;
      display: block;
      right: -20px;
      width: 13px;
      background: url(../images/ad_scroll_forwardRight.png) 0px 22px no-repeat;
    }
    .ad-gallery .ad-nav .ad-thumbs {
      overflow: hidden;
      width: 100%;

    }
    .ad-thumbs{

    }
      .ad-gallery .ad-thumbs .ad-thumb-list {
        float: left;
        width: 900000px;
        list-style: none;
      }
        .ad-gallery .ad-thumbs li {
          float: left;
          padding-right: 5px;
         
        }
          .ad-gallery .ad-thumbs li a {
            display: block;
            
          }
            .ad-gallery .ad-thumbs li a img {
              border: 3px solid #CCC;
              display: block;
            }
            .ad-gallery .ad-thumbs li a.ad-active img {
              border: 3px solid #616161;
            }
/* Can't do display none, since Opera won't load the images then */
.ad-preloads {
  position: absolute;
  left: -90000px;
  top: -90000px;
}