.baseInfo {
	width: 100%;
	box-shadow: 0 2px 2px #dddddd;
	padding-bottom: 100px;
	background-color: white;
	min-height: 77vh;
	.baseInfo-box {
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 30px;
	}
	.edit-icon {
		width: 18px;
		height: 18px;
		float: right;
		margin-top: 6px;
		cursor: pointer;
	}
	.labelRole {
		height: 34px;
		text-align: right;
		display: inline;
		line-height: 34px;
	}
	.form-control {
		&.readInput  {
			width: 80%;
			display: inline-block;
			vertical-align: middle;
		}
	}
	.editValue {
		height: 34px;
		line-height: 34px;
	}
	.readInput {
		white-space: nowrap;
	    text-overflow: ellipsis;
	    overflow: hidden;
	    width: 94%;
	    display: inline-block;
	}
	.tagBox {
		height: 150px;
		width: 100%;
		overflow-y: auto;
		input[type=checkbox], input[type=radio] {
			margin: 4px 0 0;
		}
	}
	.tagList {
		display: inline-block;
		vertical-align: top;
	}
	.timePlugin {
		display: flex;
		.start, .end {
			flex: 1;
		}
		.line {
			margin: 7px 10px 0;
			color: #999;
		}
	}
	.theMore{
		display: flex;
		justify-content: space-between;
		.front{
			display: block;
			width: 94%;
			word-break: keep-all;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	.cureentScreenName{
		width:12%;
		cursor: pointer;
		color: #337ab7;
		text-align: left;
	}
	.timeRemind {
		margin-top: 10px;
		color: @color-nobel-grey;
		font-size: 12px;
	}
	.bottomMargin{
		margin: 15px 0px;
		//word-break: break-all;
	}
	.countryCode_phone{
		width:12% !important;
	}
	.info_phone{
		width: 21.32% !important;
	}
	.info_flag{
		height: 50px;
		line-height: 50px;
		display: flex;
		align-items: center;
		cursor: pointer;
	}
	.info_padding{
		font-size: 12px;
		//margin-left: 10px;
		color: #999;
	}
	.dayLightstatus{
		height: 25px;
	}
	.nomargin {
		margin: 0 !important;
	}
	.selectWeek {
		margin-right: 10px;
	}
    .btnTagL{
		border-radius:4px 0 0 4px !important;
		padding: 3px 15px !important;
		top:3px !important;
	}
	.btnTagR{
		border-radius:0px 4px 4px 0px !important;
		padding: 3px 15px !important;
		top: 3px !important;
	}
}
